<template>
    <div
        class="absolute left-0 top-0 h-[calc(100%-48px)] w-full px-6 pb-20 pt-4 opacity-0 transition-all duration-300"
        :class="{ '!opacity-100': isShowing }"
    >
        <!-- 底部透明間曾暗色遮罩 -->
        <div
            class="absolute left-0 top-0 z-0 h-[100px] w-full transform-gpu bg-gradient-to-b from-[rgba(0,0,0,.25)] to-transparent opacity-100 transition-all duration-300"
            :class="{ '!opacity-0': isTasksFinished }"
        ></div>
        <!-- content-wrapper -->
        <div class="relative z-10 h-full w-full overflow-hidden">
            <!-- tasks -->
            <div
                class="absolute left-0 z-10 flex h-full w-full translate-y-0 transform-gpu flex-col gap-4 opacity-100 transition-all duration-300"
                :class="{ '!z-0 !-translate-y-5 !opacity-0': isTasksFinished }"
            >
                <!-- task title row -->
                <div class="flex w-full items-center justify-end">
                    <!-- <span class="text-base font-bold text-[#FFF]">
                        {{ $t("receiveGifts.giftCover.tasks.titleText") }}</span
                    > -->
                    <!-- receivers -->
                    <div
                        v-show="receiverAmount"
                        class="flex flex-grow items-center gap-2"
                    >
                        <!-- receiver avatars wrapper -->
                        <div class="mr-3 flex items-center">
                            <!-- receiver avatar -->
                            <div
                                v-for="record in firstThreeGiftReceiverRecords"
                                :key="record._id"
                                class="-mr-3 h-8 w-8 min-w-8 rounded-full border border-[#fff]"
                            >
                                <img
                                    @error="
                                        (e) =>
                                            (e.target.src =
                                                '/assets/imgs/utils/avatar/avatar_default.svg')
                                    "
                                    v-if="record.profilePhoto"
                                    :src="record.profilePhoto"
                                    alt=""
                                    class="h-full w-full rounded-full"
                                />
                                <img
                                    v-else
                                    src="/assets/imgs/utils/avatar/avatar_default.svg"
                                    alt=""
                                    class="h-full w-full rounded-full"
                                />
                            </div>
                        </div>
                        <span
                            class="whitespace-pre-line text-xs text-[#fff]"
                            v-html="
                                $t(
                                    'receiveGifts.giftCover.tasks.receiversTitleText',
                                    { receiverAmount: receiverAmount },
                                )
                            "
                        ></span>
                    </div>
                    <button
                        @click="refreshBtnOnclick"
                        :disabled="isLoading"
                        class="flex h-8 w-8 items-center justify-center rounded-full bg-[#fff] bg-opacity-60"
                    >
                        <svg-icon
                            class="h-5 w-5 text-center text-2xl font-bold text-primary"
                            :class="{ '!animate-spin': isLoading }"
                            type="mdi"
                            :path="mdiRefresh"
                        >
                        </svg-icon>
                    </button>
                </div>
                <!-- tasks wrapper -->
                <div class="no-scrollbar w-full flex-grow overflow-scroll">
                    <!-- skeleton loader -->
                    <div
                        v-if="isLoading && !tasks.length"
                        class="flex w-full flex-col gap-4"
                    >
                        <div
                            v-for="i in 4"
                            :key="i"
                            class="h-16 w-full animate-pulse rounded-2xl bg-[#fff]"
                        ></div>
                    </div>
                    <!-- tasks -->
                    <div v-else class="flex w-full flex-col gap-4">
                        <!-- task -->
                        <div
                            v-for="task in tasks"
                            :key="task._id"
                            @click="() => taskBgOnClick(task)"
                            class="flex min-h-16 w-full items-center gap-3 rounded-2xl bg-[#fff] px-4 py-3"
                        >
                            <!-- is completed or not image -->
                            <div
                                class="flex h-10 w-10 min-w-10 items-center justify-center rounded-full"
                                :class="{
                                    'bg-[#FFE5ED] line:bg-[#FFFAD6]':
                                        !task.status,
                                    'bg-[#E3FFE9]': task.status,
                                }"
                            >
                                <svg-icon
                                    class="h-5 w-5 text-center text-2xl font-bold"
                                    :class="{
                                        'text-primary': !task.status,
                                        'text-[#00DD31]': task.status,
                                    }"
                                    type="mdi"
                                    :path="
                                        task.status ? mdiCheck : mdiLockOutline
                                    "
                                >
                                </svg-icon>
                            </div>
                            <!-- task content -->
                            <div class="flex flex-grow flex-col">
                                <!-- task title -->
                                <span class="text-base font-bold text-[#111]">{{
                                    (currentLocale &&
                                        task &&
                                        task.language &&
                                        task.language[
                                            `title-${currentLocale}`
                                        ]) ||
                                    task.title
                                }}</span>
                                <!-- task description -->
                                <span class="text-xs text-[#aaa]">{{
                                    (currentLocale &&
                                        task &&
                                        task.language &&
                                        task.language[
                                            `description-${currentLocale}`
                                        ]) ||
                                    task.description
                                }}</span>
                            </div>
                            <!-- connect button group -->
                            <div
                                v-if="
                                    !task.status &&
                                    task.type === 'checkin' &&
                                    !isCheckinPending
                                "
                                class="flex items-center gap-[6px]"
                            >
                                <button
                                    @click.stop="() => taskBtnOnClick(task)"
                                    class="flex h-8 min-w-20 flex-nowrap items-center justify-center gap-1 rounded-lg bg-[#0088CC] px-2"
                                    :class="{
                                        'opacity-30': isLoading,
                                        'w-8 min-w-8':
                                            currentConnectedWalletAddress,
                                    }"
                                >
                                    <img
                                        src="assets/imgs/views/receiveGifts/logo_ton.svg"
                                        alt=""
                                        class="h-4 w-4 min-w-4"
                                    />
                                    <span
                                        v-show="!currentConnectedWalletAddress"
                                        class="text-center text-xs font-bold text-[#fff]"
                                        >{{ taskBtnText(task) }}</span
                                    >
                                </button>
                                <!-- check in button -->
                                <button
                                    v-show="currentConnectedWalletAddress"
                                    @click="checkInBtnOnClick"
                                    :disabled="isLoading"
                                    class="h-8 min-w-20 rounded-lg bg-secondary px-2 text-center text-sm font-bold text-[#fff]"
                                    :class="{ 'opacity-30': isLoading }"
                                >
                                    {{ taskBtnText(task) }}
                                </button>
                            </div>
                            <!-- check-in pending icon -->
                            <img
                                v-if="
                                    !task.status &&
                                    task.type === 'checkin' &&
                                    isCheckinPending
                                "
                                src="assets/imgs/views/receiveGifts/small_loading_icon.svg"
                                alt=""
                                class="h-5 w-5 min-w-5 animate-spin"
                            />
                            <!-- task action button -->
                            <button
                                v-if="
                                    !task.status &&
                                    task.type !== 'checkin' &&
                                    !(
                                        task.type === 'thirdPartyTask' &&
                                        (!task.link || !task.link.length)
                                    )
                                "
                                :disabled="isLoading"
                                class="h-8 min-w-20 rounded-lg bg-secondary px-2 text-center text-sm font-bold text-[#fff]"
                                :class="{ 'opacity-30': isLoading }"
                            >
                                {{ taskBtnText(task) }}
                            </button>
                            <!-- do nothing just wait icon -->
                            <img
                                v-if="
                                    task.type === 'thirdPartyTask' &&
                                    (!task.link || !task.link.length)
                                "
                                src="assets/imgs/views/receiveGifts/small_loading_icon.svg"
                                alt=""
                                class="h-5 w-5 min-w-5 animate-spin"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- congrats -->
            <div
                class="absolute left-0 z-0 flex h-full w-full translate-y-5 flex-col items-center justify-center gap-4 bg-[radial-gradient(50%_50%_at_50%_50%,rgba(0,0,0,0.16)_0%,rgba(0,0,0,0)_100%)] opacity-0 transition-all delay-500 duration-300"
                :class="{
                    '!z-10 !translate-y-0 !opacity-100': isTasksFinished,
                }"
            >
                <span class="text-center text-2xl font-bold text-[#fff]">
                    {{ $t("receiveGifts.giftCover.tasks.completedTitleText") }}
                </span>
                <span class="text-center text-base text-[#fff]">
                    {{
                        $t(
                            "receiveGifts.giftCover.tasks.completedDescriptionText1",
                        )
                    }}<br />{{
                        $t(
                            "receiveGifts.giftCover.tasks.completedDescriptionText2",
                        )
                    }}
                </span>
                <svg-icon
                    class="h-6 w-6 animate-bounce text-center text-2xl font-bold text-[#fff]"
                    type="mdi"
                    :path="mdiChevronDoubleDown"
                >
                </svg-icon>
            </div>
        </div>
        <!-- <WalletConnectPanel
            @onWalletConnected="onWalletConnected"
            @onWalletDisconnected="onWalletDisconnected"
            @onCheckinSuccess="handleCheckinSuccess"
            @onCheckinError="handleCheckinError"
            :is-wallet-connect-panel-active.sync="isWalletConnectPanelActive"
            :connected-wallet-address="connectedWalletAddress"
            :should-connect="shouldConnect"
            :should-check-in="shouldCheckIn"
        /> -->
        <Toast ref="toast" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SvgIcon from "@jamescoyle/vue-icon";
import {
    mdiRefresh,
    mdiLockOutline,
    mdiCheck,
    mdiCheckCircle,
    mdiChevronDoubleDown,
} from "@mdi/js";
import envelopeApi from "@/apis/envelope";
import taskApi from "@/apis/task";
// import WalletConnectPanel from "@/components/walletConnector/components/WalletConnectPanel.vue";
import Toast from "@/components/Toast.vue";
import userApi from "@/apis/user";

export default {
    name: "Tasks",
    components: {
        SvgIcon,
        // WalletConnectPanel,
        Toast,
    },
    props: {
        giftInfo: {
            type: Object,
            default: null,
        },
        shouldRefreshTasks: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        mdiRefresh,
        mdiLockOutline,
        mdiCheck,
        mdiCheckCircle,
        mdiChevronDoubleDown,
        // isWalletConnectPanelActive: false,
        // connectedWalletAddress: null,
        // 用來觸發 WalletConnectPanel 裡面得 connect 方法的 鉤子
        // shouldConnect: false,
        // 用來觸發 WalletConnectPanel 裡面得 checkin 方法的 鉤子
        // shouldCheckIn: false,
        isCheckinPending: false,
        isShowing: false,
        isLoading: false,
        tasksStatus: null,
        tasks: [],
        checkinUpdateTimer: null,
    }),
    computed: {
        ...mapGetters([
            "currentTokenName",
            "userInfo",
            "currentConnectedWalletAddress",
        ]),
        userId() {
            return (
                this.userInfo && this.userInfo.user && this.userInfo.user._id
            );
        },
        currentLocale() {
            return (this.$i18n && this.$i18n.locale) || null;
        },
        giftId() {
            return this.giftInfo && this.giftInfo._id;
        },
        userId() {
            return (
                this.userInfo && this.userInfo.user && this.userInfo.user._id
            );
        },
        isTasksFinished() {
            return this.tasksStatus && this.tasksStatus.finished;
        },
        receiverAmount() {
            return (this.giftInfo && this.giftInfo.numberOfPeople) || 0;
        },
        giftReceivedRecords() {
            return (this.giftInfo && this.giftInfo.records) || [];
        },
        firstThreeGiftReceiverRecords() {
            return (
                this.giftReceivedRecords && this.giftReceivedRecords.slice(0, 3)
            );
        },
    },
    watch: {
        // 當用戶點擊 sticker 試圖開啟禮物時，更新任務狀態
        shouldRefreshTasks() {
            if (this.isLoading) return;
            this.updateTaskStatus(true);
        },
    },
    methods: {
        showTasksIncompleteToast() {
            this.$bus.$emit("show-toast", {
                title: this.$t(
                    "receiveGifts.giftCover.tasks.alert.tasksIncompleteTitleText",
                ),
                message: this.$t(
                    "receiveGifts.giftCover.tasks.alert.tasksIncompleteDescriptionText",
                ),
            });
        },
        async updateTaskStatus(isTryToOpen = false) {
            this.isLoading = true;
            if (this.giftId) {
                try {
                    const res = await envelopeApi.checkTasksEnvelope(
                        this.giftId,
                    );
                    console.log("task res:", res);
                    // 置入 tasksStatus 任務狀態
                    this.tasksStatus =
                        (res && res.data && res.data.tasksStatus) || null;
                    // 任務列表
                    let tasks = this.giftInfo && this.giftInfo.tasks;
                    // 如果任務列表有內容
                    if (tasks && tasks.length) {
                        // 組合 任務信息 跟 任務狀態
                        const tasksStatus =
                            this.tasksStatus && this.tasksStatus.tasksStatus;
                        tasks = tasks.map((task) => {
                            const targetTaskStatus = tasksStatus.filter(
                                (taskStatus) => taskStatus._id === task._id,
                            )[0];
                            return {
                                ...task,
                                ...targetTaskStatus,
                            };
                        });
                        // sort task 將未完成的任務排到前面
                        tasks.sort((a, b) => a.status - b.status);
                        this.tasks = tasks;
                        console.log("sorted tasks: ", this.tasks);
                        console.log("tasksStatus: ", this.tasksStatus);
                        // 檢查是否全部任務皆已完成，若已全數完成就通知 ReceiveGifts 父組件更新 giftInfo 狀態
                        if (tasks.every((task) => task.status)) {
                            this.$emit("tasksOnCompleted");
                        } else {
                            if (isTryToOpen) {
                                this.showTasksIncompleteToast();
                            }
                        }
                    }
                } catch (error) {
                    console.error("Error fetching tasks status:", error);
                }
            }
            this.isLoading = false;
        },
        async updateCheckinStatus() {
            try {
                const res = await envelopeApi.checkTasksEnvelope(this.giftId);
                const _tasksStatus =
                    (res && res.data && res.data.tasksStatus) || null;
                console.log("_tasksStatus: ", _tasksStatus);
                if (_tasksStatus) {
                    let checkinTask =
                        this.giftInfo &&
                        this.giftInfo.tasks &&
                        this.giftInfo.tasks.filter(
                            (task) => task.type === "checkin",
                        )[0];
                    console.log("checkinTask: ", checkinTask);
                    const checkinTaskStatus =
                        _tasksStatus &&
                        _tasksStatus.tasksStatus.filter(
                            (taskStatus) => taskStatus._id === checkinTask._id,
                        )[0];
                    console.log("checkinTaskStatus: ", checkinTaskStatus);
                    if (checkinTaskStatus && checkinTaskStatus.status) {
                        console.log(
                            "checkin task has been checked, the task is completed.",
                        );
                        checkinTask = {
                            ...checkinTask,
                            ...checkinTaskStatus,
                        };
                        const tasksWithoutCheckinTask = this.tasks.filter(
                            (task) => task.type !== "checkin",
                        );
                        console.log(
                            "tasksWithoutCheckinTask: ",
                            tasksWithoutCheckinTask,
                        );
                        console.log("checkinTask: ", checkinTask);
                        const tasks = [...tasksWithoutCheckinTask, checkinTask];
                        // sort task 將未完成的任務排到前面
                        tasks.sort((a, b) => a.status - b.status);
                        this.tasks = tasks;
                        console.log("completed checkinTasks: ", this.tasks);
                        clearInterval(this.checkinUpdateTimer);
                    }
                }
            } catch (error) {
                console.error("Error fetching tasks status:", error);
            }
        },
        refreshBtnOnclick() {
            // 防止透明狀態下意外點擊
            if (this.isTasksFinished) return;
            this.updateTaskStatus();
        },
        taskBtnText(task) {
            const type = task && task.type;
            if (!type) return "-";
            if (type === "joinGroupOrChannel")
                return this.$t("receiveGifts.giftCover.tasks.taskBtnText.join");
            if (type === "openLink") {
                return task.link === "SHARE"
                    ? this.$t("receiveGifts.giftCover.tasks.taskBtnText.share")
                    : this.$t("receiveGifts.giftCover.tasks.taskBtnText.go");
            }
            if (type === "followTwitterAccount")
                return this.$t(
                    "receiveGifts.giftCover.tasks.taskBtnText.follow",
                );
            if (type === "checkin") {
                return this.currentConnectedWalletAddress &&
                    this.currentConnectedWalletAddress.length
                    ? this.$t(
                          "receiveGifts.giftCover.tasks.taskBtnText.checkIn",
                      )
                    : this.$t(
                          "receiveGifts.giftCover.tasks.taskBtnText.connect",
                      );
            }
            return this.$t("receiveGifts.giftCover.tasks.taskBtnText.go");
        },
        taskBtnOnClick(task) {
            // 防止透明狀態下意外點擊
            if (this.isTasksFinished) return;
            // 阻擋過度點擊
            if (this.isLoading) return;
            // ######## 沃草，開始囉 嘻嘻！########
            // 錢包簽到任務
            if (task.type === "checkin") {
                const isConnected =
                    this.currentConnectedWalletAddress &&
                    this.currentConnectedWalletAddress.length;
                if (isConnected) {
                    // this.isWalletConnectPanelActive = true;
                    this.$bus.$emit("switchWallet", (err) => {
                        console.log(err);
                    });
                } else {
                    // this.shouldConnect = !this.shouldConnect;
                    this.$bus.$emit("connectWallet", (err) => {
                        console.log(err);
                    });
                }
                return;
            }

            // 等待類型任務 (啥都不用做 等就對了)
            if (
                task.type === "thirdPartyTask" &&
                (!task.link || !task.link.length)
            ) {
                console.log("JUST WAIT...");
                return;
            }

            // 加入聊天室
            if (task.checkChatId) {
                if (task.link && task.type === "joinGroupAndChannel") {
                    window.Telegram.WebApp.openTelegramLink(task.link);
                } else {
                    // 移除 checkChatId 中的 "@" 符号
                    const chatId = task.checkChatId.replace("@", "");
                    // 使用清理后的 chatId 构建 Telegram 群组链接
                    const telegramGroupUrl = `https://t.me/${chatId}`;
                    // 打开 Telegram 群组链接
                    window.Telegram.WebApp.openTelegramLink(telegramGroupUrl);
                }
                return;
            }

            // 追蹤 X
            if (task.link && task.type === "followTwitterAccount") {
                this.handleTwitterLogin(task);
                return;
            }

            // OPEN LINK
            if (task.link && task.type !== "followTwitterAccount") {
                // 直接觸發就能完成的任務
                if (task.type === "openLink") {
                    taskApi.updateTask({
                        taskId: task._id,
                        status: "completed",
                    });
                }
                // 如果任務 link 是 SHARE 就拉起分享介面
                if (task.link === "SHARE") {
                    this.$emit("openInvitationPanel");
                } else {
                    const taskLink = task.link;
                    const currentUserId = this.userId;
                    const replacedLink = taskLink.replace(
                        "{tid}",
                        currentUserId,
                    );
                    window.open(replacedLink);
                }
                return;
            }
        },
        // 當任務背景被點擊時，跳轉人關任物不管任務有沒有完成都還是能被觸發
        taskBgOnClick(task) {
            // 阻擋過度點擊
            if (this.isLoading) return;
            // 等待類型任務 (啥都不用做 等就對了)
            userApi.event({
                eventType: "click_task",
                eventData: { task, redEnvelope: this.giftId },
            });

            if (
                task.type === "thirdPartyTask" &&
                (!task.link || !task.link.length)
            ) {
                console.log("JUST WAIT...");
                return;
            }
            // 加入聊天室
            if (task.checkChatId && !task.link) {
                // 移除 checkChatId 中的 "@" 符号
                const chatId = task.checkChatId.replace("@", "");
                // 使用清理后的 chatId 构建 Telegram 群组链接
                const telegramGroupUrl = `https://t.me/${chatId}`;
                // 打开 Telegram 群组链接
                window.Telegram.WebApp.openTelegramLink(telegramGroupUrl);
                return;
            }
            // 追蹤 X
            if (task.link && task.type === "followTwitterAccount") {
                this.handleTwitterLogin(task);
                return;
            }
            // OPEN LINK
            if (task.link && task.type !== "followTwitterAccount") {
                // 直接觸發就能完成的任務
                if (task.type === "openLink") {
                    taskApi.updateTask({
                        taskId: task._id,
                        status: "completed",
                    });
                }
                // 如果任務 link 是 SHARE 就拉起分享介面
                if (task.link === "SHARE") {
                    this.$emit("openInvitationPanel");
                } else {
                    const taskLink = task.link;
                    const currentUserId = this.userId;
                    const replacedLink = taskLink.replace(
                        "{tid}",
                        currentUserId,
                    );
                    window.open(replacedLink);
                }
                return;
            }
        },
        checkInBtnOnClick() {
            this.$bus.$emit("taskCheckInWallet", (err) => {
                if (!err) {
                    this.isCheckinPending = true;
                    this.checkinUpdateTimer = setInterval(
                        this.updateCheckinStatus,
                        5000,
                    );
                }
            });
        },
        async handleTwitterLogin(task) {
            try {
                const response = await taskApi.getTwitterAuthUrl(
                    this.userId,
                    task.link,
                    task.checkChatId,
                ); // 假设这是调用服务器的函数
                console.log("Twitter auth URL response:", response.data.url);
                window.Telegram.WebApp.openLink(response.data.url);
            } catch (error) {
                console.error("Failed to initiate Twitter login:", error);
            }
        },
        // onWalletConnected(address) {
        //     if (address && address.length) {
        //         this.connectedWalletAddress = address;
        //     }
        // },
        // onWalletDisconnected() {
        //     this.connectedWalletAddress = null;
        // },
        // handleCheckinSuccess(amount) {
        //     this.isCheckinPending = true;
        //     this.checkinUpdateTimer = setInterval(
        //         this.updateCheckinStatus,
        //         5000,
        //     );
        // },
        // handleCheckinError(error) {},
    },
    created() {
        this.updateTaskStatus();
        setTimeout(() => (this.isShowing = true), 300);
    },
    beforeDestroy() {
        clearInterval(this.checkinUpdateTimer);
    },
};
</script>

import Vue from "vue";
import VueRouter from "vue-router";
// import Matches from "@/views/Matches.vue";
import Assets from "@/views/assets/Assets";
import Discover from "@/views/discover/Discover.vue";
import EarnCredits from "@/views/earnCredits/EarnCredits";
import Wallet from "@/views/index/Index";
import Landing from "@/views/landing/Landing";
import ReceiveGifts from "@/views/receiveGifts/ReceiveGifts";
import ThirdPartyOrder from "@/views/thirdPartyOrder/ThirdPartyOrder";
import Detail from "@/views/detail/Detail.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "landing",
        component: Landing,
        meta: {
            isRoot: true,
        },
    },
    {
        path: "/wallet",
        name: "wallet",
        component: Wallet,
        meta: {
            isRoot: true,
        },
    },
    {
        path: "/earnCredits",
        name: "earn-credits",
        component: EarnCredits,
        meta: {
            isRoot: true,
        },
    },
    {
        path: "/discover",
        name: "discover",
        component: Discover,
        meta: {
            isRoot: true,
        },
    },
    {
        path: "/dailyCheckIn",
        name: "daily-check-in",
        component: () => import("@/views/dailyCheckIn/DailyCheckIn.vue"),
    },
    {
        path: "/creditRecords",
        name: "credit-records",
        component: () => import("@/views/creditRecords/CreditRecords.vue"),
    },
    {
        path: "/assets",
        name: "assets",
        component: Assets,
        meta: {
            isRoot: true,
        },
    },
    {
        path: "/lang",
        name: "language",
        component: () => import("@/views/language/Language.vue"),
    },
    {
        path: "/receiveGifts",
        name: "receive-gifts",
        component: ReceiveGifts,
        props: true,
    },
    {
        path: "/tokenList",
        name: "token-list",
        component: () => import("@/views/tokenList/TokenList.vue"),
    },
    {
        path: "/sendGifts",
        name: "send-gifts",
        component: () => import("@/views/sendGifts/SendGifts.vue"),
    },
    {
        path: "/giftRecords",
        name: "gift-records",
        component: () => import("@/views/giftRecords/GiftRecords.vue"),
    },
    {
        path: "/deposit",
        name: "deposit",
        component: () => import("@/views/deposit/DepositWrapper.vue"),
    },
    {
        path: "/depositQR",
        name: "depositQR",
        component: () => import("@/views/deposit/DepositQrView.vue"),
    },
    {
        path: "/withdraw",
        name: "withdraw",
        component: () => import("@/views/withdraw/withdrawWrapper.vue"),
    },
    {
        path: "/walletrecord",
        name: "wallet-record",
        component: () => import("@/views/walletRecord/WalletRecord.vue"),
    },
    {
        path: "/dotasks",
        name: "dotasks",
        component: () => import("@/views/doTask/DoTasks.vue"),
    },
    {
        path: "/promotion",
        name: "promotion",
        component: () => import("@/views/promotion/Promotion.vue"),
    },
    {
        path: "/thirdPartyOrder",
        name: "third-party-order",
        component: ThirdPartyOrder,
    },
    {
        path: "/select-token",
        component: () => import("@/views/deposit/components/SelectToken.vue"),
        children: [
            {
                path: "deposit",
                name: "select-token-deposit",
                component: () =>
                    import("@/views/deposit/components/SelectToken.vue"),
            },
            {
                path: "withdraw",
                name: "select-token-withdraw",
                component: () =>
                    import("@/views/deposit/components/SelectToken.vue"),
            },
            {
                path: ":pathMatch(.*)*",
                redirect: (to) => {
                    const { query } = to;
                    if (query.to === "withdraw") return { path: "withdraw" };
                    return { path: "deposit" };
                },
            },
        ],
    },
    {
        path: "/invitation",
        name: "invitation",
        component: () => import("@/views/invitation/Invitation.vue"),
    },
    {
        path: "/spin",
        name: "spin",
        component: () => import("@/views/spin/Spin.vue"),
    },
    {
        path: "/category",
        name: "category",
        component: () => import("@/views/category/Category.vue"),
    },
    {
        path: "/category/:categoryItem",
        name: "category-item",
        component: () => import("@/views/category/components/CategoryItem.vue"),
    },
    {
        path: "/detail",
        name: "detail",
        component: Detail,
        props: true,
    },
    {
        path: "*",
        redirect: { name: "landing" },
    },
];

const router = new VueRouter({
    routes,
});

export default router;

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isExist)?_c('div',{staticClass:"background fixed left-0 top-0 z-10 flex h-screen w-screen justify-center bg-[#fff] p-6 transition-all duration-300",class:{ opening: _vm.isAnimating, '!p-0': _vm.isDoingTask },on:{"click":_vm.giftCoverOnclick}},[_c('div',{staticClass:"relative flex h-full w-full flex-col items-center justify-end overflow-hidden rounded-3xl shadow-[0_10px_20px_rgba(_100,_100,_100,_0.5)]",class:{ '!rounded-none': _vm.isDoingTask },style:({ background: _vm.backgroundColor })},[_c('div',{staticClass:"envelope-cover absolute left-0 top-0 h-[calc(100%-168px)] w-full rounded-t-3xl",class:{
                opening: _vm.isAnimating,
                '!rounded-t-none': _vm.isDoingTask,
            }},[_c('div',{staticClass:"relative h-full w-full rounded-t-3xl",class:{ '!rounded-t-none': _vm.isDoingTask },style:({
                    backgroundImage: `url(${
                        `https://www.tongifts.app/static/tg_gift_img/cover_` +
                        _vm.cover +
                        `.png`
                    })`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'bottom',
                    maskImage: `url(${
                        _vm.isLocalhost
                            ? 'assets/imgs/utils/maskTemplate/template.svg'
                            : '/appcenterstatic/template.svg'
                    })`,
                    maskSize: 'cover',
                    maskPosition: 'bottom',
                    maskRepeat: 'no-repeat',
                    WebkitMaskImage: `url(${
                        _vm.isLocalhost
                            ? 'assets/imgs/utils/maskTemplate/template.svg'
                            : '/appcenterstatic/template.svg'
                    })`,
                    WebkitMaskSize: 'cover',
                    WebkitMaskPosition: 'bottom',
                    WebkitMaskRepeat: 'no-repeat',
                })}),(!_vm.isDoingTask && _vm.isShowWords && !_vm.isGiftDisabled)?_c('span',{staticClass:"absolute bottom-1/3 left-1/2 max-w-[350px] -translate-x-1/2 text-center text-2xl font-bold",style:({ color: _vm.desTextColor })},[_vm._v(_vm._s(_vm.$t("receiveGifts.giftCover.titleText", { senderName: _vm.giftSenderName, })))]):_vm._e(),(_vm.isGiftDisabled)?_c('div',{staticClass:"absolute bottom-1/2 left-0 flex w-full translate-y-1/3 flex-col items-center gap-4 bg-[radial-gradient(50%_50%_at_50%_50%,rgba(0,0,0,0.16)_0%,rgba(0,0,0,0)_100%)] py-10"},[_c('span',{staticClass:"text text-center text-base leading-5 text-[#fff]",domProps:{"innerHTML":_vm._s(_vm.$t('receiveGifts.giftCover.exclusiveText'))}}),_c('img',{staticClass:"w-full",attrs:{"src":"assets/imgs/views/receiveGifts/exclusive_gift.svg","alt":""}})]):_vm._e(),(_vm.isDoingTask)?_c('Tasks',{attrs:{"gift-info":_vm.giftInfo,"should-refresh-tasks":_vm.shouldRefreshTasks},on:{"tasksOnCompleted":function($event){_vm.isTasksCompleted = true},"openInvitationPanel":function($event){return _vm.$emit('inviteBtnOnClick')}}}):_vm._e(),_c('div',{staticClass:"sticker absolute bottom-[-40px] left-[calc(50%-50px)] z-0 flex h-[100px] w-[100px] origin-center transform-gpu items-center justify-center rounded-full bg-[#ffd1a0] shadow-[0px_4px_4px_rgba(0,0,0,0.25)]",class:{
                    flipping: _vm.isLoading,
                    'animate-pulse': _vm.isCheckingTask,
                    pinging:
                        (!_vm.isLocked &&
                            !_vm.isCheckingTask &&
                            !_vm.isAnimating &&
                            !_vm.isGiftDisabled) ||
                        (_vm.isDoingTask &&
                            _vm.isTasksCompleted &&
                            !_vm.isAnimating &&
                            !_vm.isGiftDisabled),
                },style:({
                    background: _vm.stickerBgColor,
                }),on:{"click":_vm.stickerOnClick}},[_c('div',{staticClass:"flex h-full w-full items-center justify-center rounded-full border-4 hover:cursor-pointer",style:({ borderColor: _vm.openBtnColor })},[(_vm.isGiftDisabled)?_c('svg-icon',{staticClass:"h-12 w-12 text-[#fff]",attrs:{"type":"mdi","path":_vm.mdiLockOutline,"size":48}}):_c('span',{staticClass:"text-center text-xl font-bold",style:({ color: _vm.openBtnColor })},[_vm._v(" "+_vm._s(_vm.$t("receiveGifts.giftCover.stickerText"))+" ")])],1)])],1),(_vm.isDoingTask && _vm.isInviteType)?_c('InviteBtn',{attrs:{"gift-info":_vm.giftInfo},on:{"inviteBtnOnClick":function($event){return _vm.$emit('inviteBtnOnClick')}}}):_vm._e(),(!_vm.isDoingTask)?_c('button',{staticClass:"absolute bottom-10 left-1/2 flex h-6 -translate-x-1/2 items-center justify-center rounded-full bg-[#000] bg-opacity-30 px-3 text-center text-xs text-[#fff]",on:{"click":function($event){$event.stopPropagation();return _vm.viewReceivedDetailBtnOnClick.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("receiveGifts.giftCover.viewReceivedDetailBtnText"))+" ")]):_vm._e()],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"absolute left-0 top-0 h-full w-full bg-[#F2F2F7]"},[(!_vm.isLoading)?_c('div',{staticClass:"relative flex h-full w-full flex-col justify-between bg-[#F2F2F7]"},[_c('img',{staticClass:"absolute left-0 top-0 z-0 w-full object-contain",attrs:{"src":`/assets/imgs/views/wallet/${_vm.currentPlatform === 'telegram' ? 'wallet-bg.svg' : 'wallet-line-bg.svg'}`,"alt":""}}),_c('div',{staticClass:"relative z-10 flex h-12 w-full flex-shrink-0 flex-grow-0 items-end justify-between px-6"},[_c('div',{staticClass:"flex w-full items-center justify-between"},[_c('div',{staticClass:"flex items-center gap-2"},[_c('img',{staticClass:"h-8 w-8 rounded-full border border-[#fff]",attrs:{"src":_vm.currentUserProfilePicUrl ||
                            '/assets/imgs/utils/avatar/avatar_default.svg',"alt":"user_profile_picture"},on:{"error":(e) =>
                                (e.target.src =
                                    '/assets/imgs/utils/avatar/avatar_default.svg')}}),_c('span',{staticClass:"max-w-[200px] truncate text-base font-bold text-[#111]"},[_vm._v(_vm._s(_vm.currentUserFirstName))])]),_c('button',{staticClass:"flex h-6 w-6 items-center justify-center",on:{"click":() => _vm.$router.push({ name: 'language' })}},[_c('img',{staticClass:"h-full w-full",attrs:{"src":"assets/imgs/icons/lang_icon.svg","alt":""}})])])]),_c('div',{staticClass:"relative flex w-full flex-col gap-4 px-6 py-4"},[_c('PromotionBanner')],1),_c('div',{staticClass:"envelope relative min-h-[300px] w-full flex-shrink flex-grow flex-col rounded-t-3xl bg-[#3A1691] line:bg-black"},[_c('div',{staticClass:"relative h-[calc(100%-44px)] w-full",on:{"click":_vm.sendGiftOnclick}},[_c('div',{staticClass:"relative h-full w-full rounded-t-[20px]",style:({
                        backgroundImage: `url(/assets/imgs/views/wallet/${this.currentPlatform === 'telegram' ? '' : 'line-'}wallet-gift-cover.png)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'bottom',
                        maskImage: `url(${
                            _vm.isLocalhost
                                ? 'assets/imgs/utils/maskTemplate/template.svg'
                                : '/appcenterstatic/template.svg'
                        })`,
                        maskSize: 'cover',
                        maskPosition: 'bottom',
                        maskRepeat: 'no-repeat',
                        WebkitMaskImage: `url(${
                            _vm.isLocalhost
                                ? 'assets/imgs/utils/maskTemplate/template.svg'
                                : '/appcenterstatic/template.svg'
                        })`,
                        WebkitMaskSize: 'cover',
                        WebkitMaskPosition: 'bottom',
                        WebkitMaskRepeat: 'no-repeat',
                    })}),_c('span',{staticClass:"absolute left-1/2 top-1/3 -translate-x-1/2 -translate-y-1/2 text-center text-2xl font-bold text-[#fff]"},[_vm._v(_vm._s(_vm.$t("index.sendGiftsToFriends")))]),_c('div',{staticClass:"z-1 absolute bottom-[-20px] left-1/2 h-16 w-[208px] -translate-x-1/2 rounded-[10px] p-1 hover:cursor-pointer",style:(_vm.stickerOuterBgStyle)},[_c('div',{staticClass:"flex h-full w-full items-center justify-center rounded-lg bg-secondary",style:(_vm.stickerInnerShadowStyle)},[_c('span',{staticClass:"text-center text-lg font-bold text-[#fff]"},[_vm._v(_vm._s(_vm.$t("index.sendAGiftNow")))])])])]),_c('button',{staticClass:"absolute right-2 top-1 flex h-10 w-10 items-center justify-center text-2xl",on:{"click":function($event){$event.stopPropagation();return _vm.giftRecordsOnclick.apply(null, arguments)}}},[_c('svg-icon',{staticClass:"text-[#fff]",attrs:{"type":"mdi","path":_vm.mdiHistory,"size":24}})],1)])]):_c('Loading',{attrs:{"full-screen":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
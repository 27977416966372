<template>
    <div class="flex w-full flex-col gap-3 rounded-2xl bg-white p-4">
        <div class="flex items-center justify-between">
            <span class="text-lg font-bold text-[#111111]">{{ title }}</span>
            <button
                class="flex items-center text-sm capitalize text-[#9699b4]"
                @click="showRecommendedBox"
            >
                {{ $t("discover.viewAll") }}

                <svg-icon
                    type="mdi"
                    :path="mdiChevronRight"
                    :size="20"
                    class="-mr-1 text-[#9699b4]"
                ></svg-icon>
            </button>
        </div>
        <!-- Items -->
        <EssentialItem
            v-if="appList.length > 0"
            v-for="(item, index) in appList"
            :key="index"
            :logo="item.logo"
            :appName="item.appName"
            :keyword="item.keyword"
            :detailLink="item.detailLink"
            :rewards="item.rewards"
            :miniappLink="item.miniappLink"
        />
        <RecommendedBox
            :title="title"
            :appList="appList"
            :description="description"
            ref="recommendedBox"
        />
    </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiChevronRight } from "@mdi/js";
import EssentialItem from "./EssentialItem.vue";
import RecommendedBox from "./RecommendedBox.vue";

export default {
    name: "Essential",
    components: {
        SvgIcon,
        EssentialItem,
        RecommendedBox,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        description: String,
        appList: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        visible: false,
        mdiChevronRight,
    }),
    methods: {
        showRecommendedBox() {
            this.$refs.recommendedBox.show();
        },
    },
};
</script>

<style lang="scss" scoped></style>

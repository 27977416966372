<template>
    <div class="flex w-full flex-col items-center gap-4">
        <div class="flex w-full items-center justify-between">
            <span class="text-base font-bold text-[#111111]">{{
                $t("utils.app")
            }}</span>
            <button @click="$router.push({ name: 'category' })">
                <svg-icon
                    type="mdi"
                    :path="mdiFormatListBulleted"
                    :size="24"
                    class="text-[#111111]"
                ></svg-icon>
            </button>
        </div>
        <!-- Slide -->
        <Carousel
            v-if="list.length"
            class="app-carousel"
            :carouselSettings="carouselSettings"
            :class-name="list.length > 1 ? 'pl-6' : 'pl-4'"
        >
            <AppSlide
                v-for="(item, index) in list"
                :key="index"
                :logo="item.logo"
                :banner="item.banner"
                :title="item.title"
                :description="item.description"
                :detailLink="item.detailLink"
                :miniappLink="item.miniappLink"
            />
        </Carousel>
    </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mapGetters } from "vuex";

import { mdiFormatListBulleted } from "@mdi/js";
import Carousel from "@/components/Carousel.vue";
import AppSlide from "./AppSlide.vue";

export default {
    name: "DiscoverApp",
    components: {
        SvgIcon,
        Carousel,
        AppSlide,
    },
    props: {
        list: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        mdiFormatListBulleted,

        carouselSettings: {
            slidesToShow: 1.05,
            autoplay: true,
            autoplaySpeed: 5000,
        },
    }),
    computed: {
        ...mapGetters(["discover"]),
    },
};
</script>

<style lang="scss">
.app-carousel {
    .slick-slide {
        padding-right: 12px;
    }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex h-14 w-screen items-center gap-4 bg-[#fff] px-6",staticStyle:{"box-shadow":"0px -4px 8px 0px rgba(0, 0, 0, 0.02)"}},_vm._l((_vm.renderNavItems()),function(item,index){return _c('button',{key:index,staticClass:"flex h-14 w-1/3 flex-col items-center justify-center gap-[2px]",attrs:{"disabled":_vm.currentRouteName === item.name},on:{"click":function($event){return _vm.linkOnclick(item.name)}}},[(item.img)?_c('img',{class:item.img.class,attrs:{"src":item.img.currentSrc && _vm.currentRouteName === item.name
                    ? item.img.currentSrc
                    : item.img.src,"alt":item.img.alt},on:{"error":(e) =>
                    item.img.backupSrc
                        ? (e.target.src =
                              '/assets/imgs/utils/token/default.png')
                        : undefined}}):_c('svg-icon',{staticClass:"h-6 text-[#9699B5]",class:{
                '!text-primary': _vm.currentRouteName === item.name,
            },attrs:{"type":"mdi","path":item.icon.path,"size":24}}),_c('span',{staticClass:"text-[10px] leading-3 text-[#9699B5]",class:{
                'font-bold !text-primary': _vm.currentRouteName === item.name,
            }},[_vm._v(_vm._s(item.content))])],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
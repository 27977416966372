<template>
    <div class="absolute left-0 top-0 h-full w-full">
        <div
            v-if="!isFetching && !isLoading && giftConfig"
            class="relative flex h-full w-full flex-col"
        >
            <!-- ended stamp -->
            <img
                v-if="showEndStamp"
                src="assets/imgs/views/receiveGifts/ended_stamp.svg"
                alt=""
                class="absolute left-1/2 top-1/2 z-0 w-[264px] -translate-x-1/2 -translate-y-1/2 opacity-50"
            />
            <!-- top content -->
            <div class="relative z-[1] flex w-full flex-col gap-6 pb-8">
                <!-- envelope header -->
                <div class="relative h-[100px] w-full overflow-hidden">
                    <div
                        class="absolute h-full w-full"
                        :style="{
                            backgroundImage: `url(${
                                `https://www.tongifts.app/static/tg_gift_img/cover_` +
                                coverId +
                                `.png`
                            })`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'bottom',
                            maskImage: `url(${
                                isLocalhost
                                    ? 'assets/imgs/utils/maskTemplate/template.svg'
                                    : '/appcenterstatic/template.svg'
                            })`,
                            maskSize: 'cover',
                            maskPosition: 'bottom',
                            maskRepeat: 'no-repeat',
                            WebkitMaskImage: `url(${
                                isLocalhost
                                    ? 'assets/imgs/utils/maskTemplate/template.svg'
                                    : '/appcenterstatic/template.svg'
                            })`,
                            WebkitMaskSize: 'cover',
                            WebkitMaskPosition: 'bottom',
                            WebkitMaskRepeat: 'no-repeat',
                        }"
                    ></div>
                </div>
                <!-- content -->
                <div class="flex w-full flex-col justify-center gap-4 px-6">
                    <!-- 送禮者標題 -->
                    <span
                        v-if="isForCheck"
                        class="text-center text-xl font-bold text-[#111]"
                        >{{
                            $t("receiveGifts.sender_gifts_forCheck", {
                                sender: giftSenderName,
                            })
                        }}</span
                    >
                    <span
                        v-else
                        class="text-center text-xl font-bold text-[#111]"
                        >{{
                            $t("receiveGifts.sender_giftsForYou", {
                                sender: giftSenderName,
                            })
                        }}</span
                    >
                    <!-- 當前獲取總金額 -->
                    <div
                        v-if="!isForCheck"
                        class="relative flex items-center justify-center"
                    >
                        <div class="flex items-end justify-center gap-1">
                            <img
                                @error="
                                    (e) =>
                                        (e.target.src =
                                            '/assets/imgs/utils/token/default.png')
                                "
                                class="h-10 w-10"
                                :src="`assets/imgs/utils/token/${giftTokenName}.png`"
                                alt="token-image"
                            />
                            <span
                                class="text-[40px] font-bold leading-none text-secondary"
                            >
                                {{ currentUserReceivedTotalAmount }}
                            </span>
                            <span
                                class="pb-1 text-base font-bold leading-none text-secondary"
                            >
                                {{ giftTokenName }}
                            </span>
                        </div>
                        <!-- back to home btn -->
                        <button
                            @click="() => $router.push('/assets')"
                            class="absolute right-0 top-1/2 flex h-8 w-8 -translate-y-1/2 items-center justify-center bg-white bg-opacity-0"
                        >
                            <img
                                :src="`assets/imgs/views/receiveGifts/${currentPlatform === 'telegram' ? '' : 'line_'}wallet_icon.svg`"
                                alt=""
                                class="h-8 w-8"
                            />
                        </button>
                    </div>
                    <!-- 當前獲取金額 -->
                    <div
                        v-if="isInviteRewarded && !isForCheck"
                        class="flex items-center justify-center gap-3 text-center"
                    >
                        <div
                            v-if="currentClaimedAmount"
                            class="flex flex-nowrap items-center gap-[2px]"
                        >
                            <span class="text-xs text-[#aaa]"
                                >{{ $t("receiveGifts.openedStatusPrefixText") }}
                            </span>
                            <span class="text-xs text-[#FF5D8F]">{{
                                `${currentClaimedAmount}`
                            }}</span>
                            <span class="text-xs text-[#aaa]">/</span>
                            <span class="text-xs text-[#aaa]">{{
                                `${totalRewardsAmount}`
                            }}</span>
                        </div>
                        <span
                            v-if="currentUserReceivedGiftAmount"
                            class="whitespace-nowrap text-center text-xs text-[#aaa]"
                            >{{
                                $t(
                                    "receiveGifts.currentReceivedGiftAmountText",
                                    {
                                        amount: currentUserReceivedGiftAmount,
                                        tokenName: giftTokenName,
                                    },
                                )
                            }}</span
                        >
                    </div>
                </div>
            </div>
            <!-- divider -->
            <div class="relative z-[1] h-2 w-full bg-[#F8F8FB]"></div>
            <!-- receive status -->
            <div class="flex w-full items-center justify-between px-6 py-2">
                <span class="text-xs text-[#aaa]">{{
                    $t("receiveGifts.receivedStatusText", {
                        quantStat: giftReceivedQuantityStatusText,
                        amountStat: giftReceivedAmountStatusText,
                        token: giftTokenName,
                    })
                }}</span>
                <span
                    class="items-center"
                    @click="optionSelectOnclick"
                    v-if="showGiftActionMenu"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="h-6 w-6"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                        />
                    </svg>
                </span>
            </div>
            <!-- divider -->
            <div class="relative z-[1] mx-6 h-[1px] bg-[#DDDFEC]"></div>
            <!-- lower content -->
            <div
                class="no-scrollbar relative z-[1] flex w-full flex-grow flex-col items-center justify-between overflow-scroll px-6"
            >
                <!-- receiver list -->
                <div
                    v-if="giftReceivedRecords && giftReceivedRecords.length"
                    class="flex w-full flex-col gap-4"
                >
                    <!-- receivers -->
                    <div
                        v-for="(record, index) in giftReceivedRecords"
                        :key="record._id"
                        class="w-full"
                    >
                        <div class="flex gap-3 pb-3 pt-4">
                            <img
                                @error="
                                    (e) =>
                                        (e.target.src =
                                            '/assets/imgs/utils/avatar/avatar_default.svg')
                                "
                                v-if="record.profilePhoto"
                                :src="record.profilePhoto"
                                alt=""
                                class="h-10 w-10 rounded-full"
                            />
                            <img
                                v-else
                                src="/assets/imgs/utils/avatar/avatar_default.svg"
                                alt=""
                                class="h-10 w-10 rounded-full"
                            />
                            <!-- <img v-else src="/assets/imgs/utils/avatar/avatar_default.svg" alt="" class="w-10 h-10 rounded-full"> -->
                            <div class="flex flex-grow flex-col gap-1">
                                <div
                                    class="flex w-full flex-grow items-center justify-between"
                                >
                                    <div class="flex items-center">
                                        <span
                                            class="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap text-base font-bold text-[#111]"
                                            >{{
                                                record.first_name || "-"
                                            }}</span
                                        >
                                        <!-- crown icons -->
                                        <img
                                            v-if="index === 0 && giftType == 0"
                                            class="ml-1 h-5"
                                            src="assets/imgs/icons/receiver_rank1.svg"
                                            alt="Gold Crown"
                                        />
                                        <img
                                            v-else-if="
                                                index === 1 && giftType == 0
                                            "
                                            class="ml-1 h-5"
                                            src="assets/imgs/icons/receiver_rank2.svg"
                                            alt="Silver Crown"
                                        />
                                        <img
                                            v-else-if="
                                                index === 2 && giftType == 0
                                            "
                                            class="ml-1 h-5"
                                            src="assets/imgs/icons/receiver_rank3.svg"
                                            alt="Bronze Crown"
                                        />
                                    </div>
                                    <!-- 累計加總金額字段，找不到新版紅包的 lastAmount時，就去找舊版紅包的 info.amount -->
                                    <span
                                        class="text-base font-bold text-[#111]"
                                        >{{
                                            `${(record.info && record.info.amount) || 0} ${giftTokenName}`
                                        }}</span
                                    >
                                </div>
                                <div
                                    class="flex w-full items-center justify-between gap-5"
                                >
                                    <span class="text-xs text-[#aaa]">{{
                                        localTimeText(record.createdAt)
                                    }}</span>
                                    <span
                                        v-if="
                                            shouldRecordClaimedTimeShown(record)
                                        "
                                        class="whitespace-nowrap text-xs text-[#aaa]"
                                        >{{
                                            $t(
                                                "receiveGifts.receiverOpenedTimesText",
                                                {
                                                    additionalTimes:
                                                        recordClaimedTimeAmount(
                                                            record,
                                                        ),
                                                },
                                            )
                                        }}</span
                                    >
                                </div>
                            </div>
                        </div>
                        <!-- divider -->
                        <div class="h-[1px] w-full bg-[#DDDFEC]"></div>
                    </div>
                    <!-- more... -->
                    <div
                        class="flex w-full flex-col gap-1 pb-3"
                        v-if="giftReceivedQuantity.gt(100)"
                    >
                        <div class="flex w-full items-center justify-between">
                            <span
                                class="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap text-base text-[#111]"
                            >
                                {{ $t("receiveGifts.andMore") }}</span
                            >
                        </div>
                    </div>
                </div>
                <!-- no receiver -->
                <div
                    v-else
                    class="flex h-full w-full flex-col items-center gap-4 pt-4"
                >
                    <img
                        src="assets/imgs/views/receiveGifts/defaultPage-img.png"
                        alt=""
                        class="h-[120px] w-[120px]"
                    />
                    <div class="flex w-full flex-col items-center gap-2">
                        <span
                            class="text-center text-[20px] font-bold text-[#000]"
                            >{{ $t("receiveGifts.defaultPageTitleText") }}</span
                        >
                        <span class="text-center text-sm text-[#6f6f6f]">{{
                            $t("receiveGifts.defaultPageDescriptionText")
                        }}</span>
                    </div>
                </div>
            </div>
            <!-- send gifts button -->
            <div class="w-full px-6 py-6" v-if="showSendGiftsButton">
                <button
                    @click="sendGiftButtonOnclick"
                    class="h-14 min-h-14 w-full rounded-lg bg-primary text-center text-lg font-bold text-[#fff]"
                    :style="{ background: stickerBgColor }"
                >
                    {{ $t("receiveGifts.sendGiftsBtnText") }}
                </button>
            </div>
            <!-- share gifts button -->
            <div class="w-full px-6 py-6" v-if="showOpenAgainButton">
                <button
                    @click="openInvitationPanel"
                    :disabled="isCheckingTask"
                    class="flex h-14 min-h-14 w-full items-center justify-center gap-2 rounded-lg bg-[#FF5D8F]"
                    :class="{ 'opacity-30': isCheckingTask }"
                    :style="{ background: stickerBgColor }"
                >
                    <img
                        v-if="isCheckingTask"
                        src="assets/imgs/icons/btn_loading.svg"
                        alt=""
                        class="h-6 w-6 animate-spin"
                    />
                    <span class="text-center text-lg font-bold text-[#fff]">{{
                        $t("receiveGifts.openAgainBtnText")
                    }}</span>
                </button>
            </div>
            <!-- open now button -->
            <div
                class="flex w-full flex-col items-center gap-1 px-6 py-6"
                v-if="!isForCheck && showOpenNowButton"
            >
                <span class="text-sm text-[#000]">
                    {{ $t("receiveGifts.extraOpenChanceText") }}
                </span>
                <button
                    @click="openNow"
                    class="gold-gradient h-14 min-h-14 w-full rounded-lg text-center text-lg font-bold text-[#fff]"
                >
                    {{ $t("receiveGifts.openNowBtnText") }}
                </button>
            </div>
            <!-- invite button for gift sender -->
            <div
                v-if="showSendToMoreFriendsButton"
                class="flex w-full flex-col items-center gap-1 px-6 py-6"
            >
                <button
                    @click="openInvitationPanel"
                    class="flex h-14 min-h-14 w-full items-center justify-center gap-2 rounded-lg bg-[#FFC90C]"
                    :style="{ background: stickerBgColor }"
                >
                    <img
                        src="assets/imgs/icons/invitationPanel_share.svg"
                        alt=""
                        class="h-6 w-6"
                    />
                    <span class="text-center text-lg font-bold text-[#fff]">{{
                        $t("receiveGifts.inviteBtnText")
                    }}</span>
                </button>
            </div>
            <!-- invite button for non invite type gifts -->
            <div
                v-if="showShareWithFriendsButton"
                class="flex w-full flex-col items-center gap-1 px-6 py-6"
            >
                <button
                    @click="openInvitationPanel"
                    class="flex h-14 min-h-14 w-full items-center justify-center rounded-lg bg-[#FFC90C]"
                    :style="{ background: stickerBgColor }"
                >
                    <span class="text-center text-lg font-bold text-[#fff]">{{
                        $t("receiveGifts.shareBtnText")
                    }}</span>
                </button>
            </div>
        </div>
        <Loading v-else :full-screen="true" />
        <!-- gift cover -->
        <!-- 正在開啟禮物 || (沒有在fetching && 沒有已經領取過 && (還沒結束 || 已經開始撥開禮物動畫) && 不是查看用途 && 已經有giftConfig) -->
        <GiftCover
            v-if="showGiftCover"
            @giftCoverOnOpen="giftCoverOnOpen"
            @endIsOpening="endIsOpening"
            @inviteBtnOnClick="() => (isInvitationPanelActive = true)"
            :is-loading="isLoading"
            :is-opening="isOpening"
            :gift-info="giftInfo"
            :gift-sender-name="giftSenderName"
            :is-received="startCoverAnimation"
            :is-checking-task="isCheckingTask"
            :cover-id="coverId"
            :is-gift-disabled="isGiftDisabled"
            :is-locked="isLocked"
            :gift="giftConfig"
        />
        <template v-if="!isFetching && !isLoading && giftConfig">
            <!-- 禮物發放者 查看/收回禮物 用的面板 -->
            <OptionSelectPanel
                :is-option-select-panel-active.sync="isOptionSelectPanelActive"
                @RetrieveGiftsOptionOnclick="RetrieveGiftsOptionOnclick"
                @shareOptionOnClick="openInvitationPanel"
            />
            <!-- 邀請面板 -->
            <NewInvitationPanel
                :isActive="isInvitationPanelActive"
                :isForCheck="isForCheck"
                :isInviteType="isInviteType"
                :invitesRequired="giftInfo.invitesRequired"
                :invitationInfo="giftInfo.invitationInfo"
                :inviteRewardCount="giftInfo.inviteRewardCount"
                :giftInfo="giftInfo"
                :giftId="giftInfo._id"
                :userId="userInfo.user._id"
                @close="isInvitationPanelActive = false"
            />
        </template>
        <!-- open gift audio -->
        <audio ref="audioPlayer" src="/assets/audios/openGift.mp3"></audio>
        <!-- Captcha -->
        <div v-if="showCaptcha" class="" id="overlay">
            <vue-turnstile
                class="absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4"
                @verified="handleVerify"
                :site-key="cfkey"
                @error="handleVerifyError"
            />
        </div>
        <toast ref="toast"></toast>
    </div>
</template>

<script>
import envelopeApi from "@/apis/envelope";
import Loading from "@/components/Loading.vue";
import Toast from "@/components/Toast.vue";
import VueTurnstile from "@gaviti/vue-turnstile";
import Decimal from "decimal.js";
import moment from "moment";
import { mapGetters } from "vuex";
import GiftCover from "./components/giftCover/GiftCover.vue";
import NewInvitationPanel from "./components/invitationPanel/NewInvitationPanel.vue";
import OptionSelectPanel from "./components/OptionSelectPanel.vue";
import liff from "@line/liff";

export default {
    name: "ReceiveGifts",
    components: {
        Loading,
        GiftCover,
        OptionSelectPanel,
        NewInvitationPanel,
        VueTurnstile,
        Toast,
    },
    data: () => ({
        isLoading: false,
        isFetching: false,
        isCheckingTask: false,
        // 訪問此頁面是否是為了查看禮物
        isForCheck: false,
        alreadyReceived: false,
        giftInfo: null,
        giftType: -1,
        isTaskGift: true,
        tasksStatus: null,
        isOptionSelectPanelActive: false,
        startCoverAnimation: false,
        isInvitationPanelActive: false,
        isOpening: false,
        currentUserReceivedGiftAmount: null,
        showCaptcha: false,
        cfkey: process.env.VUE_APP_CF_SITE_KEY,
    }),
    computed: {
        ...mapGetters([
            "currentTokenName",
            "userInfo",
            "tokenList",
            "currentToken",
            "platform",
        ]),
        isLocalhost() {
            return window.location.hostname === "localhost";
        },
        currentPlatform() {
            return this.platform;
        },
        currentUserId() {
            return (
                this.userInfo && this.userInfo.user && this.userInfo.user._id
            );
        },

        // ******** 禮物配置相關 ********
        //? 禮物的禮物配置參數
        giftConfig() {
            return (
                (this.giftInfo &&
                    this.giftInfo.info &&
                    this.giftInfo.info.gift) ||
                null
            );
        },
        //? 禮物封面 ID
        coverId() {
            if (
                this.giftInfo &&
                this.giftInfo.info &&
                this.giftInfo.info.coverId &&
                this.giftConfig
            )
                return this.giftConfig.cover || this.giftInfo.info.coverId;
            return null;
        },
        //? send 按钮背景填充色
        stickerBgColor() {
            console.log("gift", this.giftConfig);
            return (
                (this.giftConfig &&
                    this.giftConfig.theme &&
                    this.giftConfig.theme.sendBtnColor) ||
                "#FF2D2D"
            );
        },
        //? 是否為邀請型禮物 (可透過邀請增加開啟次數)
        isInviteType() {
            return !!(
                this.giftInfo &&
                this.giftInfo.invitesRequired &&
                this.giftInfo.invitesRequired > 0
            );
        },
        //? 显示发送者的名字
        giftSenderName() {
            if (this.giftConfig)
                return (
                    this.giftConfig.senderName ||
                    (this.giftInfo && this.giftInfo.first_name) ||
                    "Someone"
                );
            else
                return (this.giftInfo && this.giftInfo.first_name) || "Someone";
        },
        //? 禮物的對應的 token
        giftToken() {
            return (
                this.tokenList &&
                this.tokenList.find((token) => token._id === this.giftTokenName)
            );
        },
        //? 禮物的 token 名稱
        giftTokenName() {
            return (
                this.giftInfo && this.giftInfo.info && this.giftInfo.info.token
            );
        },

        // ******** 禮物狀態相關 ********
        //? 是否已經透過邀請獲取到更多開啟次數
        isInviteRewarded() {
            return !!(this.giftInfo && this.giftInfo.invitationInf);
        },
        //? 當前用戶已經領取的次數
        currentClaimedAmount() {
            return (
                (this.giftInfo &&
                    this.giftInfo.selfRecord &&
                    this.giftInfo.selfRecord[0] &&
                    this.giftInfo.selfRecord[0].times) ||
                0
            );
        },
        //? 當前用戶透過邀請獲取，尚可開啟的次數
        availableInviteRewardsAmount() {
            return (
                this.giftInfo &&
                this.giftInfo.invitationInfo &&
                this.giftInfo.invitationInfo.rewardsAvailable &&
                new Decimal(this.giftInfo.invitationInfo.rewardsAvailable || 0)
            );
        },
        //? 是否還有未領取的透過邀請獲取的開啟次數
        isInviteRewardRemain() {
            return (
                this.availableInviteRewardsAmount &&
                this.availableInviteRewardsAmount.gt(0)
            );
        },
        //? 當前用戶已經獲取的開啟次數
        totalRewardsAmount() {
            return this.availableInviteRewardsAmount.plus(
                new Decimal(this.currentClaimedAmount || 0),
            );
        },
        //? 禮物是否已被收回
        isGiftCanceled() {
            return this.giftInfo && this.giftInfo.canceled;
        },
        //? 禮物是否已經終止
        isGiftEnded() {
            // 禮物如果被收回 或是 領完 就是結束了
            return !this.isGiftQuantityRemain || this.isGiftCanceled;
        },
        //? 當前用戶是否已透過普通方式領取過禮物
        isGiftNormReceived() {
            if (
                this.giftInfo &&
                this.giftInfo.selfRecord &&
                Array.isArray(this.giftInfo.selfRecord)
            ) {
                return this.giftInfo.selfRecord.some(
                    (record) =>
                        record &&
                        record.data &&
                        (record.data.isInvite === null ||
                            record.data.isInvite === false ||
                            record.data.isInvite === undefined),
                );
            } else {
                return false;
            }
        },
        //? 當前用戶是否已透過邀請方式領取過禮物
        isGiftInviteReceived() {
            if (
                this.giftInfo &&
                this.giftInfo.selfRecord &&
                Array.isArray(this.giftInfo.selfRecord)
            ) {
                return this.giftInfo.selfRecord.some(
                    (record) =>
                        record &&
                        record.user === record.data &&
                        record.data.isInvite &&
                        record.data.isInvite === true,
                );
            } else {
                return false;
            }
        },
        //? 是否已經領過
        isAlreadyReceived() {
            if (this.isGiftNormReceived) return true;
            if (
                this.isTaskGift &&
                !(this.tasksStatus && this.tasksStatus.finished)
            ) {
                // task is not finished
                return this.isGiftInviteReceived;
            } else {
                // task is finished
                console.log("isAlreadyReceived:", this.isGiftNormReceived);
                return this.isGiftNormReceived;
            }
        },
        //? 禮物是否被領完了
        isGiftQuantityRemain() {
            return (
                this.giftInfo &&
                this.giftInfo.left &&
                this.giftInfo.left.$numberDecimal &&
                new Decimal(this.giftInfo.left.$numberDecimal || 0).gt("0")
            );
        },
        //? 禮物的總份數
        totalGiftQuantity() {
            return new Decimal(
                (this.giftInfo &&
                    this.giftInfo.info &&
                    this.giftInfo.info.number) ||
                    0,
            );
        },
        //? 禮物已被領取的份數
        giftReceivedQuantity() {
            const receivedGiftQuantity = new Decimal(
                (this.giftInfo &&
                    this.giftInfo.leftNumber &&
                    this.giftInfo.leftNumber) ||
                    0,
            );
            return this.totalGiftQuantity.minus(receivedGiftQuantity);
        },
        //? 當前禮物被領取的數量狀態 ("被領取份數/總份數")
        giftReceivedQuantityStatusText() {
            return `${this.giftReceivedQuantity.toString()}/${this.totalGiftQuantity.toString()}`;
        },
        //? 當前禮物被領取的金額狀態 ("被領取金額/總金額")
        giftReceivedAmountStatusText() {
            const totalGiftAmount = new Decimal(
                (this.giftInfo &&
                    this.giftInfo.info &&
                    this.giftInfo.info.totalAmount) ||
                    0,
            );
            const leftGiftAmount = new Decimal(
                (this.giftInfo &&
                    this.giftInfo.left &&
                    this.giftInfo.left.$numberDecimal) ||
                    0,
            );
            const receivedGiftAmount = totalGiftAmount
                .minus(leftGiftAmount)
                .toFixed(this.giftToken ? this.giftToken.tokenPrecision : 3);
            return `${receivedGiftAmount}/${totalGiftAmount}`;
        },
        //? 當前用戶已領取的總金額
        currentUserReceivedTotalAmount() {
            return (
                this.giftInfo &&
                this.giftInfo.selfRecord &&
                this.giftInfo.selfRecord[0] &&
                this.giftInfo.selfRecord[0].amount &&
                this.giftInfo.selfRecord[0].amount.$numberDecimal
            );
        },
        //? 禮物領取用戶列表
        giftReceivedRecords() {
            return (this.giftInfo && this.giftInfo.records) || [];
        },
        //? 當前禮物是否不可使用
        isGiftDisabled() {
            return !!(this.giftInfo && this.giftInfo.cantClaim);
        },
        //? 禮物是否還不可領取
        isLocked() {
            if (
                this.isTaskGift &&
                !(this.tasksStatus && this.tasksStatus.finished)
            ) {
                // task is not finished
                if (this.isInviteRewardRemain)
                    // 任務沒完成，卻有可開啟次數 (????)
                    return false; // OPEN
                else return true; // Lock
            } else {
                // task is finished
                return false; // OPEN
            }
        },
        //? 禮物任務是否已經完成
        isTaskFinished() {
            if (this.isTaskGift) {
                return this.tasksStatus && this.tasksStatus.finished;
            } else {
                return true;
            }
        },

        // ******** UI邏輯相關 *********
        //? 禮物封面是否顯示
        showGiftCover() {
            return (
                this.isOpening ||
                (!this.isFetching &&
                    !this.isAlreadyReceived &&
                    (!this.isGiftEnded || this.startCoverAnimation) &&
                    !this.isForCheck &&
                    this.giftConfig)
            );
        },
        //? end戳記是否顯示
        showEndStamp() {
            return this.isGiftEnded;
        },
        //? 禮物的 more 操作選單顯示情境
        showGiftActionMenu() {
            return (
                this.isGiftQuantityRemain &&
                this.giftInfo &&
                !this.giftInfo.canceled &&
                this.currentUserId &&
                this.currentUserId == this.giftInfo.user
            );
        },
        // **** 收禮頁底部按鈕顯示邏輯 ****
        //? [Send Gifts] 按鈕顯示情境
        showSendGiftsButton() {
            return (
                !this.isForCheck &&
                !this.isInviteRewardRemain &&
                !this.isTaskGift
            );
        },
        //? [Open Again] 按鈕顯示情境
        showOpenAgainButton() {
            return (
                !this.isForCheck &&
                !this.isGiftEnded &&
                !this.isInviteRewardRemain &&
                this.isTaskGift &&
                this.isInviteType
            );
        },
        //? [Open Now] 按鈕顯示情境
        showOpenNowButton() {
            return (
                !this.isForCheck &&
                !this.isGiftEnded &&
                this.isInviteRewardRemain
            );
        },
        //? [Send to More Friends] 按鈕顯示情境
        showSendToMoreFriendsButton() {
            return !this.isGiftEnded && this.isForCheck;
        },
        //? [Share With Friends] 按鈕顯示情境
        showShareWithFriendsButton() {
            return (
                !this.isForCheck &&
                !this.isGiftEnded &&
                !this.isInviteRewardRemain &&
                !this.isInviteType &&
                this.isTaskGift
            );
        },
    },
    watch: {
        $route(to, from) {
            if (to && to.query && to.query.check && to.query.check === "1") {
                this.isForCheck = true;
            } else {
                this.isForCheck = false;
            }
        },
    },
    methods: {
        shareGiftButtonOnclick() {
            window.Telegram.WebApp.switchInlineQuery(
                `envelope:invite:${this.giftInfo._id}:${this.userInfo.user._id}`,
                ["users", "bots", "groups", "channels"],
            );
        },
        sendGiftButtonOnclick() {
            // 不能送禮的代幣
            console.log("giftToken: ", this.giftToken);
            if (this.giftToken && this.giftToken.cantSend) {
                this.$router.push({ name: "wallet" });
                return;
            }
            this.chooseToken(this.giftTokenName);
            this.$router.push({ name: "send-gifts" });
        },
        chooseToken(tokenName) {
            // const tokenName = tokenName;
            const targetToken =
                this.tokenList &&
                this.tokenList.find((token) => token._id === tokenName);
            if (targetToken) {
                // console.log("set token:", targetToken);
                this.$store.commit("setCurrentToken", targetToken);
            }
        },
        optionSelectOnclick() {
            this.isOptionSelectPanelActive = true;
        },
        RetrieveGiftsOptionOnclick() {
            this.isOptionSelectPanelActive = false;
            const giftId = this.giftInfo._id.toString();
            if (giftId) {
                this.endGift(giftId);
            }
        },
        async endGift(giftId) {
            this.isLoading = true;
            try {
                let res = await envelopeApi.endEnvelope(giftId);
                console.log("endGift res: ", res);
                if (res.status !== 200) {
                    throw new Error(res.statusText);
                } else {
                    res = await envelopeApi.getEnvelope(giftId);
                    this.giftInfo = res.data;
                    window.Telegram.WebApp.showPopup({
                        title: this.$t("utils.success"),
                        message: this.$t("receiveGifts.alert.giftEnded"),
                    });
                }
            } catch (err) {
                console.log("endGift err: ", err);
                const errorCode =
                    err &&
                    err.response &&
                    err.response.data &&
                    err.response.data.errorCode;
                if (errorCode === 10005) {
                    this.getGiftInfo(giftId);
                }
                console.log(errorCode);
            }
            this.isLoading = false;
        },
        localTimeText(timeText) {
            return moment(timeText).format("YYYY/MM/DD HH:mm:ss");
        },
        async openGift(giftId) {
            this.isOpening = true;
            this.isLoading = true;
            try {
                let isInvite = false;
                if (this.giftInfo.invitationInfo) isInvite = true;
                if (this.isTaskFinished && !this.isGiftNormReceived)
                    isInvite = false;
                console.log("isInvite:", isInvite);
                let res = await envelopeApi.openEnvelope(giftId, isInvite);
                console.log("openGift res: ", res);
                if (res.status !== 200) {
                    throw new Error(res.statusText);
                } else {
                    this.playOpenGiftSound();
                    // res = await this.getGiftInfo(giftId);
                    // ? 兼容舊版紅包沒有 gift 字段信息
                    this.giftInfo =
                        res.data && res.data.info && res.data.info.gift
                            ? res.data
                            : {
                                  ...this.giftInfo,
                                  ...res.data,
                                  info: {
                                      ...res.data.info,
                                      gift: this.giftInfo.info.gift,
                                  },
                              };
                    this.startCoverAnimation = true;
                }
                // 更新當次開到的金額
                this.currentUserReceivedGiftAmount =
                    this.giftInfo &&
                    this.giftInfo.selfRecord &&
                    this.giftInfo.selfRecord[0] &&
                    this.giftInfo.selfRecord[0].lastAmount;
            } catch (err) {
                console.log("openGift err: ", err);
                const errorCode =
                    err &&
                    err.response &&
                    err.response.data &&
                    err.response.data.errorCode;
                const errorText =
                    err &&
                    err.response &&
                    err.response.data &&
                    err.response.data.statusText;
                if (errorCode === 10005) {
                    this.getGiftInfo(giftId);
                }
                if (
                    window.Telegram &&
                    window.Telegram.WebApp &&
                    window.Telegram.WebApp.initData &&
                    err &&
                    err.response &&
                    err.response.data
                ) {
                    window.Telegram.WebApp.showPopup({
                        title: this.$t("utils.error"),
                        message: errorText,
                    });
                }
            }
            this.isLoading = false;
            // this.isOpening = false;
        },
        async getGiftInfo(giftId, isFixGiftId = false, inviterId = null) {
            this.isFetching = true;
            try {
                const res = await envelopeApi.getEnvelope(
                    giftId,
                    isFixGiftId,
                    inviterId,
                );
                console.log("getGiftInfo res: ", res);
                if (res.status !== 200) {
                    throw new Error(res.statusText);
                } else {
                    // ? 兼容舊版紅包沒有 gift 字段信息
                    if (!(res.data && res.data.info && res.data.info.gift)) {
                        this.getGift();
                    }
                    this.giftInfo = res.data;
                    this.showCaptcha =
                        this.giftInfo.info &&
                        this.giftInfo.info.gift &&
                        this.giftInfo.info.gift.turnstile;
                    console.log("this.showCaptcha", this.showCaptcha);

                    // this.alreadyReceived = this.giftInfo.selfRecord;
                    if (this.giftInfo.info) {
                        this.giftType = this.giftInfo.info.type; // 0 random, 1 fix
                    }
                    this.isFetching = false;
                    this.isCheckingTask = true;
                    if (
                        this.giftInfo.tasks &&
                        this.giftInfo.tasks.length > 0 &&
                        !this.isGiftEnded
                    ) {
                        this.isTaskGift = true;
                        try {
                            let statusRes =
                                await envelopeApi.checkTasksEnvelope(
                                    this.giftInfo._id,
                                );
                            if (
                                statusRes &&
                                statusRes.data &&
                                statusRes.data.tasksStatus
                            )
                                this.tasksStatus = statusRes.data.tasksStatus;
                            // console.log("taskStatus",this.tasksStatus)
                        } catch (error) {
                            console.log("taskStatus err: ", error);
                        }
                    } else {
                        this.isTaskGift = false;
                    }
                    this.isCheckingTask = false;
                }
            } catch (err) {
                console.log("getGiftInfo err: ", err);
                const errorText =
                    err &&
                    err.response &&
                    err.response.data &&
                    err.response.data.statusText;
                window.Telegram.WebApp.showPopup({
                    title: this.$t("utils.error"),
                    message: errorText,
                });
                this.isFetching = false;
            }
        },
        // ? 兼容舊版紅包沒有 gift 字段信息
        async getGift() {
            try {
                const res = await envelopeApi.getGifts();
                if (res.status !== 200) {
                    throw new Error();
                } else {
                    console.log("getGift res: ", res);
                    const gifts = res.data;
                    const targetGift = gifts.filter(
                        (gift) => gift._id == this.giftInfo.info.coverId,
                    )[0];
                    this.giftInfo = {
                        ...this.giftInfo,
                        info: { ...this.giftInfo.info, gift: targetGift },
                    };
                }
            } catch (err) {
                console.log("getGift err: ", err);
            }
        },
        updateGiftInfo() {
            const giftId = this.$route.query.id;
            const fixGiftId = this.$route.query.fgid; // fix gift id
            const inviterId = this.$route.query.inviterid;
            if (giftId) {
                this.getGiftInfo(giftId, false, inviterId);
            } else if (fixGiftId) {
                this.getGiftInfo(fixGiftId, true, inviterId);
            }
        },
        giftCoverOnOpen() {
            // this.showCaptcha = true;
            // ? temporary remove captcha verification
            this.handleOpenGift();
        },
        endIsOpening() {
            this.isOpening = false;
            this.startCoverAnimation = false;
        },
        openInvitationPanel() {
            this.isInvitationPanelActive = true;
        },
        openNow() {
            // this.showCaptcha = true;
            // ? temporary remove captcha verification
            this.handleOpenGift();
        },
        shouldRecordClaimedTimeShown(record) {
            return (
                record && record.times && new Decimal(record.times || 0).gt(1)
            );
        },
        recordClaimedTimeAmount(record) {
            return record && record.times && new Decimal(record.times).minus(1);
        },
        playOpenGiftSound() {
            const audio = this.$refs.audioPlayer;
            audio.currentTime = 0;
            setTimeout(() => audio.play(), 300);
        },
        handleVerify(token) {
            if (token) {
                this.showCaptcha = false;
                this.handleOpenGift();
            }
        },
        handleOpenGift() {
            const giftId = this.giftInfo && this.giftInfo._id;
            if (giftId) {
                this.openGift(giftId);
            }
        },
        handleVerifyError(code) {
            this.$bus.$emit("show-toast", {
                type: "error",
                message: code,
            });
        },
    },
    created() {
        // 路由指定了訪問此頁面是為了查看禮物
        const isForCheck =
            this.$route.query.check && this.$route.query.check === "1";
        this.isForCheck = isForCheck;
        this.updateGiftInfo();
    },
};
</script>
<style scoped>
.gold-gradient {
    background: linear-gradient(135deg, #ffd700 0%, #f8ff00 100%);
    color: #111; /* 更改文本颜色以确保可读性 */
}

#overlay {
    position: fixed;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}
</style>

<template>
    <div
        class="inline-flex flex-col justify-center gap-3 rounded-2xl border border-[#ff5c8e] bg-[#ffe5ed] p-4"
    >
        <a-progress class="gift-progress" :percent="progress" status="active" />

        <div class="inline-flex items-center gap-3">
            <!-- Logo -->
            <img
                :src="logo"
                alt="gift-logo"
                @error="
                    (e) =>
                        (e.target.src = '/assets/imgs/utils/token/default.png')
                "
                class="size-10"
            />
            <!-- Prize -->
            <div>
                <div class="text-base font-bold uppercase text-[#111111]">
                    {{ `${totalAmount} ${token}` }}
                </div>
                <div class="text-xs text-[#aaaaaa]">
                    {{
                        `${$t("sentGifts.received")} ${received.number}/${received.totalNumber},
                    ${received.amount}/${received.totalAmount} ${token}`
                    }}
                </div>
            </div>
        </div>

        <!-- Open -->
        <button
            class="rounded-lg bg-[#ffc90b] px-4 py-3 text-sm font-bold text-white"
            @click="openGift"
        >
            {{ $t("utils.open") }}
        </button>
    </div>
</template>

<script>
export default {
    name: "DetailGift",
    props: {
        logo: String,
        id: {
            type: String,
            required: true,
        },
        progress: {
            type: Number,
            required: true,
        },
        token: {
            type: String,
            required: true,
        },
        totalAmount: {
            type: String,
            required: true,
        },
        received: {
            type: Object,
            required: true,
        },
    },
    methods: {
        openGift() {
            this.$router.push(`/receiveGifts?id=${this.id}`);
        },
    },
};
</script>

<style lang="scss">
.gift-progress {
    .ant-progress {
        &-text {
            color: #ff5d8f;
            font-size: 12px;
        }
        &-bg {
            background-color: #ff5d8f;
        }
    }
}
</style>

<template>
    <transition name="fade">
        <div v-if="isActive" class="fixed left-0 top-0 z-10 h-screen w-screen">
            <!-- Overlay -->
            <div
                class="absolute left-0 top-0 z-[-1] h-full w-full bg-[#000] bg-opacity-60"
                @click="close"
            ></div>
            <!-- Panel -->
            <div
                class="absolute bottom-0 left-0 flex w-full flex-col items-center justify-center rounded-t-xl bg-[#FFF] p-6"
            >
                <!-- Close Button -->
                <button
                    @click="close"
                    class="absolute right-4 top-4 flex h-6 w-6 items-center justify-center rounded-full bg-[#F0F0F0] text-base text-[#fff]"
                >
                    &times;
                </button>
                <!-- panel content -->
                <div class="flex w-full flex-col gap-6">
                    <!-- text wrapper -->
                    <div class="flex w-full flex-col items-center gap-3">
                        <!-- title -->
                        <span
                            v-if="isInviteType && !isForCheck"
                            class="w-full text-center text-lg font-bold text-[#111111]"
                            >{{
                                inviteOnlyNew
                                    ? $t(
                                          "receiveGifts.newInvitationPanel.newtitleText",
                                      )
                                    : $t(
                                          "receiveGifts.newInvitationPanel.titleText",
                                      )
                            }}</span
                        >
                        <span
                            v-else
                            class="w-full text-center text-lg font-bold text-[#111111]"
                            >{{
                                $t(
                                    "receiveGifts.newInvitationPanel.shareTitleText",
                                )
                            }}</span
                        >
                        <!-- description -->
                        <span
                            v-if="isInviteType && !isForCheck"
                            class="w-full text-center text-base text-[#111]"
                            >{{
                                inviteOnlyNew
                                    ? $t(
                                          "receiveGifts.newInvitationPanel.newdescriptionText",
                                          { invitesRequired: invitesRequired },
                                      )
                                    : $t(
                                          "receiveGifts.newInvitationPanel.descriptionText",
                                          { invitesRequired: invitesRequired },
                                      )
                            }}</span
                        >
                        <span
                            v-else
                            class="w-full text-center text-base text-[#111]"
                            >{{
                                $t(
                                    "receiveGifts.newInvitationPanel.shareDescriptionText",
                                )
                            }}</span
                        >
                    </div>
                    <!-- progress wrapper -->
                    <div
                        v-if="isInviteType"
                        class="flex w-full flex-col items-end gap-2"
                    >
                        <!-- progress bar outer -->
                        <div
                            class="relative h-[10px] w-full rounded-full bg-[#F0F0F0]"
                        >
                            <!-- progress bar inner -->
                            <div
                                class="absolute left-0 top-0 h-full rounded-full bg-primary"
                                :class="`w-[${currentProgressPercentage}%]`"
                            ></div>
                        </div>
                        <!-- progress text -->
                        <div class="flex items-center gap-1">
                            <span class="pr-1 text-sm text-[#777]"
                                >{{
                                    $t(
                                        "receiveGifts.newInvitationPanel.invited",
                                    )
                                }}:</span
                            >
                            <span class="text-sm text-primary">{{
                                validInvitesCount
                            }}</span>
                            <span class="text-sm text-[#777]">/</span>
                            <span class="text-sm text-[#777]">{{
                                invitesRequired
                            }}</span>
                        </div>
                    </div>
                    <!-- share methods -->
                    <div class="flex w-full justify-center gap-2">
                        <!-- copy link -->
                        <div class="flex w-20 flex-col items-center gap-2">
                            <!-- icon -->
                            <button
                                :disabled="!currentUserName"
                                :class="{ 'opacity-30': !currentUserName }"
                                @click="shareTextToChart"
                                class="flex h-12 w-12 items-center justify-center rounded-full bg-primary-15"
                            >
                                <img
                                    :src="`assets/imgs/icons/${currentPlatform === 'telegram' ? '' : 'line_'}invitationPanel_link.svg`"
                                    alt="Link"
                                    class="h-6 w-6"
                                />
                            </button>
                            <!-- text -->
                            <span
                                class="w-full text-center text-xs text-[#111]"
                                >{{
                                    $t(
                                        "receiveGifts.newInvitationPanel.copyLink",
                                    )
                                }}</span
                            >
                        </div>
                        <!-- copy share text -->
                        <div class="flex w-20 flex-col items-center gap-2">
                            <!-- icon -->
                            <button
                                :disabled="!currentUserName"
                                :class="{ 'opacity-30': !currentUserName }"
                                @click="copyShareText"
                                class="flex h-12 w-12 items-center justify-center rounded-full bg-primary-15"
                            >
                                <img
                                    :src="`assets/imgs/icons/${currentPlatform === 'telegram' ? '' : 'line_'}invitationPanel_copy.svg`"
                                    alt="Copy"
                                    class="h-6 w-6"
                                />
                            </button>
                            <!-- text -->
                            <span
                                class="w-full text-center text-xs text-[#111]"
                                >{{
                                    $t(
                                        "receiveGifts.newInvitationPanel.copyText",
                                    )
                                }}</span
                            >
                        </div>
                        <!-- qr code -->
                        <div class="flex w-20 flex-col items-center gap-2">
                            <!-- icon -->
                            <button
                                :disabled="!currentUserName"
                                :class="{ 'opacity-30': !currentUserName }"
                                @click="displayQRCode"
                                class="flex h-12 w-12 items-center justify-center rounded-full bg-primary-15"
                            >
                                <img
                                    :src="`assets/imgs/icons/${currentPlatform === 'telegram' ? '' : 'line_'}invitationPanel_image.svg`"
                                    alt="Image"
                                    class="h-6 w-6"
                                />
                            </button>
                            <!-- text -->
                            <span
                                class="w-full text-center text-xs text-[#111]"
                                >{{
                                    $t(
                                        "receiveGifts.newInvitationPanel.saveQRCode",
                                    )
                                }}</span
                            >
                        </div>
                        <!-- more -->
                        <div
                            v-if="isNavigatorShareAvailable"
                            class="flex w-20 flex-col items-center gap-2"
                        >
                            <!-- icon -->
                            <button
                                :disabled="!currentUserName"
                                :class="{ 'opacity-30': !currentUserName }"
                                @click="moreBtnOnclick"
                                class="flex h-12 w-12 items-center justify-center rounded-full bg-primary-15"
                            >
                                <img
                                    :src="`assets/imgs/icons/${currentPlatform === 'telegram' ? '' : 'line_'}invitationPanel_more.svg`"
                                    alt="More"
                                    class="h-6 w-6"
                                />
                            </button>
                            <!-- text -->
                            <span
                                class="w-full text-center text-xs text-[#111]"
                                >{{
                                    $t("receiveGifts.newInvitationPanel.more")
                                }}</span
                            >
                        </div>
                    </div>
                    <!-- share to TG button -->
                    <button
                        @click="confirm"
                        class="flex h-14 w-full items-center justify-center gap-3 rounded-lg bg-primary"
                    >
                        <img
                            src="assets/imgs/icons/invitationPanel_share.svg"
                            alt=""
                            class="h-6 w-6"
                        />
                        <span class="text-base font-bold text-[#fff]">{{
                            $t("receiveGifts.newInvitationPanel.shareBtnText")
                        }}</span>
                    </button>
                </div>
            </div>
            <InvitationQRCodeModal
                :isActive="isInvitationQRCodeModalActive"
                :giftInfo="giftInfo"
                @close="isInvitationQRCodeModalActive = false"
            />
            <!-- gif modal -->
            <div v-if="showGifModal" class="gif-modal">
                <div class="modal-content">
                    <span class="close-modal" @click="closeModal">&times;</span>
                    <p class="modal-tip">
                        <b>{{
                            $t("receiveGifts.newInvitationPanel.gifModalTitle")
                        }}</b
                        >:
                        {{
                            $t(
                                "receiveGifts.newInvitationPanel.gifModalDescription",
                            )
                        }}:
                    </p>
                    <img
                        src="assets/imgs/utils/gifs/demo1_cut.gif"
                        alt="Demo GIF"
                        class="demo-gif"
                    />
                    <div class="modal-options">
                        <input
                            type="checkbox"
                            id="dontShowAgain"
                            v-model="dontShowAgain"
                        />
                        <label for="dontShowAgain">{{
                            $t(
                                "receiveGifts.newInvitationPanel.dontShowAgainText",
                            )
                        }}</label>
                    </div>
                    <button @click="handleUnderstand" class="understand-button">
                        {{ $t("receiveGifts.newInvitationPanel.iUnderstand") }}
                    </button>
                </div>
            </div>
            <toast ref="toast"></toast>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from "vuex";
import Decimal from "decimal.js";
import InvitationQRCodeModal from "./components/InvitationQRCodeModal.vue";
import Toast from "@/components/Toast.vue";

export default {
    name: "NewInvitationPanel",
    components: {
        InvitationQRCodeModal,
        Toast,
    },
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        isForCheck: {
            type: Boolean,
            default: false,
        },
        isInviteType: {
            type: Boolean,
            default: false,
        },
        invitesRequired: {
            type: Number,
            default: 0,
        },
        invitationInfo: {
            type: Object,
            default: null,
        },
        inviteRewardCount: {
            type: Number,
            default: 0,
        },
        giftInfo: {
            type: Object,
            default: null,
        },
        giftId: {
            type: String,
            default: "",
        },
        userId: {
            type: String,
            default: "",
        },
    },
    data: () => ({
        isInvitationQRCodeModalActive: false,
        isNavigatorShareAvailable: false,
        targetAddress: "", // 这应该设置为你要生成二维码的地址
        showGifModal: false,
        dontShowAgain: false,
        // ...其余的data属性
    }),
    computed: {
        ...mapGetters(["userInfo", "platform"]),
        giftUserName() {
            return (
                (this.giftInfo &&
                    this.giftInfo.info &&
                    this.giftInfo.info.gift &&
                    this.giftInfo.info.gift.senderName) ||
                null
            );
        },
        inviteOnlyNew() {
            return (
                (this.giftInfo &&
                    this.giftInfo.info &&
                    this.giftInfo.info.gift &&
                    this.giftInfo.info.gift.inviteOnlyNew) ||
                false
            );
        },
        currentUserName() {
            return (
                (this.userInfo &&
                    this.userInfo.user &&
                    this.userInfo.user.tg_info &&
                    this.userInfo.user.tg_info.first_name) ||
                null
            );
        },
        giftTokenName() {
            return (
                this.giftInfo && this.giftInfo.info && this.giftInfo.info.token
            );
        },
        totalGiftAmount() {
            return `${new Decimal(
                (this.giftInfo &&
                    this.giftInfo.info &&
                    this.giftInfo.info.totalAmount) ||
                    0,
            )}`;
        },
        // 计算满足条件的邀请数量
        validInvitesCount() {
            if (!this.invitationInfo || !this.invitationInfo.invites) {
                return 0; // 如果没有invitationInfo或没有invites数组，返回0
            }
            return this.invitationInfo.invites.reduce((count, invite) => {
                if (invite.completed && !invite.rewardClaimed) {
                    return count + 1; // 计算满足条件的项目数量
                }
                return count;
            }, 0);
        },
        remainCount() {
            let rewardsUsded = 0;
            if (this.invitationInfo && this.invitationInfo.rewardsUsed) {
                rewardsUsded = this.invitationInfo.rewardsUsed;
            }
            return this.inviteRewardCount - rewardsUsded;
        },
        currentProgressPercentage() {
            return this.invitesRequired
                ? (this.validInvitesCount / this.invitesRequired) * 100
                : 0;
        },
        currentPlatform() {
            return this.platform;
        },
    },
    methods: {
        async updateUserInfo() {
            try {
                const err = await this.$store.dispatch("updateUserInfo");
                if (err) {
                    throw new Error(JSON.stringify(err));
                }
            } catch (err) {
                window.Telegram.WebApp.showAlert(err);
            }
        },
        closeModal() {
            this.showGifModal = false;
        },
        calculateMarkPosition(index, total) {
            // 计算每个标记的位置，确保其从0开始，并且最后一个标记在100%
            return `${(index / total) * 100}%`;
        },
        copyText(text) {
            console.log("copy text:", text);
            if (navigator.clipboard && window.isSecureContext) {
                // navigator.clipboard API method
                navigator.clipboard
                    .writeText(text)
                    .then(() => {
                        this.showToast(
                            "success",
                            this.$t(
                                "receiveGifts.newInvitationPanel.copiedText",
                            ),
                        );
                    })
                    .catch((err) => {
                        console.error("Could not copy text: ", err);
                    });
            } else {
                // Fallback method for older browsers or non-secure context
                let textArea = document.createElement("textarea");
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                try {
                    let successful = document.execCommand("copy");
                    let msg = successful ? "successful" : "unsuccessful";
                    console.log("Fallback: Copying text command was " + msg);
                    this.showToast(
                        "success",
                        this.$t("receiveGifts.newInvitationPanel.copiedText"),
                    );
                } catch (err) {
                    console.error("Fallback: Oops, unable to copy", err);
                }

                document.body.removeChild(textArea);
            }
        },
        shareTextToChart() {
            let shareText = `\n\n🎁${this.giftUserName} has sent a ${this.totalGiftAmount} $${this.giftTokenName} gift for you !\n\n👆Click Link above to open 🌈⬆️`;
            shareText = encodeURIComponent(shareText);
            window.open(
                `https://t.me/share/url?url=${this.targetAddress}&text=${shareText}`,
            );
        },
        copyShareText() {
            console.log("totalGiftAmount: ", this.totalGiftAmount);
            console.log("giftTokenName: ", this.giftTokenName);
            const shareText = `${this.targetAddress}\n\n🎁${this.giftUserName} has sent a ${this.totalGiftAmount} $${this.giftTokenName} gift for you !\n\n👆Click Link above to open 🌈⬆️`;
            this.copyText(shareText);
        },
        displayQRCode() {
            console.log("display qr code");
            this.isInvitationQRCodeModalActive = true;
        },
        async moreBtnOnclick() {
            const shareText = `${this.targetAddress}\n\n🎁${this.giftUserName} has sent a ${this.totalGiftAmount} $${this.giftTokenName} gift for you !\n\n👆Click Link above to open 🌈⬆️`;
            try {
                navigator.share({
                    text: shareText,
                    url: this.targetAddress,
                });
            } catch (err) {
                console.log("navigator share error: ", error);
            }
        },
        triggerToast(type, message) {
            // 假设你有一个按钮调用此方法
            console.log("show-toast:", message);
            this.$bus.$emit("show-toast", {
                type: type,
                message: message,
            });
        },
        showToast(type, message) {
            this.triggerToast(type, message);
        },
        close() {
            this.$emit("close");
        },
        handleUnderstand() {
            this.showGifModal = false;
            if (this.dontShowAgain) {
                localStorage.setItem("dontShowGifModal", "true");
            }
            this.continueConfirm();
        },
        confirm() {
            if (localStorage.getItem("dontShowGifModal") === "true") {
                console.log("go: 1");
                this.continueConfirm();
            } else {
                // this.showGifModal = true;
                if (
                    window.Telegram.WebApp.platform === "macos" ||
                    window.Telegram.WebApp.platform === "ios"
                ) {
                    // 如果是 iOS 或 macOS，显示 GIF
                    console.log("go: 2");
                    this.showGifModal = true;
                } else {
                    console.log("go: 3");
                    this.continueConfirm();
                }
            }
        },
        continueConfirm() {
            console.log("this.userId:", this.userId);
            console.log("this.giftId:", this.giftId);
            if (window.Telegram.WebApp.platform === "macos") {
                const botUrl = process.env.VUE_APP_BOT_URL;
                const activeLink = `${botUrl}?start=sendid_${this.giftId}_${this.userId}`;
                console.log("send gift active link: ", activeLink);
                window.Telegram.WebApp.disableClosingConfirmation();
                window.Telegram.WebApp.openTelegramLink(activeLink);
                window.Telegram.WebApp.close();
            } else {
                window.Telegram.WebApp.switchInlineQuery(
                    `envelope:invite:${this.giftId}:${this.userId}`,
                    ["users", "bots", "groups", "channels"],
                );
            }
        },
    },
    created() {
        this.updateUserInfo();
    },
    mounted() {
        if (window.navigator && window.navigator.share) {
            this.isNavigatorShareAvailable = true;
        }
        this.targetAddress = `${process.env.VUE_APP_BOT_URL}/${process.env.VUE_APP_MINIAPP}?startapp=o_${this.giftId}_${this.userId}`;
    },
};
</script>

<style lang="scss" scoped>
.gif-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1001;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative; /* 为关闭按钮设置相对定位的上下文 */
    display: flex; /* 使用 flex 布局 */
    flex-direction: column; /* 子元素垂直堆叠 */
    align-items: center; /* 子元素水平居中 */
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
    color: #999;
}

.modal-tip {
    font-size: 16px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    color: #333;
}

.modal-options {
    margin-top: 15px;
    font-size: 14px;
    color: #666;
    display: flex; /* 使用 flex 布局 */
    align-items: center; /* 垂直居中对齐 */
}

.modal-options label {
    margin-left: 5px; /* 添加5px的左边距 */
}

.demo-gif {
    width: 600px; /* 定宽，保持 GIF 宽度不变 */
    height: auto; /* 高度自适应，保持原始宽高比 */
    border: 2px dashed #4dabb0; /* 设置灰色虚线边框 */
    padding: 4px; /* 设置2px的内边距 */
}

.understand-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
</style>

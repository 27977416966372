<template>
    <div
        class="relative rounded-2xl bg-gradient-to-br from-[#ffe6d0] to-[#ffb0da] p-4 hover:cursor-pointer line:from-[#fff07a] line:to-[#ceff00]"
        @click.stop="$router.push('/invitation')"
    >
        <img
            class="absolute inset-0 w-full"
            src="/assets/imgs/views/earnCredits/confetti.svg"
            alt=""
        />
        <div class="relative z-10 flex flex-col gap-2">
            <img
                class=""
                src="/assets/imgs/views/earnCredits/people.png"
                alt=""
            />
            <div class="inline-flex flex-col items-center gap-1">
                <div class="text-center font-bold text-[#111111]">
                    {{ $t("earnCredits.invite.title") }}!
                </div>
                <div class="text-sm text-[#ff5c8e] line:text-black">
                    {{ $t("earnCredits.invite.subtitle") }}
                </div>
                <div
                    class="inline-flex items-center justify-center gap-1 text-sm font-bold text-[#ff5c8e] line:text-black"
                >
                    <img
                        class="size-4"
                        src="/assets/imgs/utils/token/GFT.png"
                        alt=""
                    />
                    <span>20 - 50 GFT</span>
                </div>
            </div>
            <div class="mx-auto flex w-fit items-center justify-center">
                <span class="text-sm text-white line:text-black">{{
                    $t("earnCredits.invite.viewDetail")
                }}</span>
                <svg-icon
                    type="mdi"
                    :path="mdiChevronRight"
                    :size="20"
                    class="text-white line:text-black"
                ></svg-icon>
            </div>
            <button
                class="max-h-10 rounded-lg bg-primary px-6 py-3 font-bold leading-tight text-white"
                @click.stop="onOpenInvitationPanel"
            >
                {{ $t("earnCredits.invite.inviteBtnText") }}
            </button>
        </div>
    </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiChevronRight } from "@mdi/js";

export default {
    name: "InviteFriends",
    components: {
        SvgIcon,
    },
    data: () => ({
        mdiChevronRight,
    }),
    methods: {
        onOpenInvitationPanel() {
            this.$emit("openInvitationPanel");
        },
    },
};
</script>

<template>
    <div
        @click="handleBannerClick"
        ref="openADBanner"
        class="openADJsSDKBanner w-full rounded-lg"
        :class="className"
        publisherld="69"
        zoneld="95"
        v-if="openAd.bannerAd"
    ></div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "OpenAd",
    props: {
        className: String,
    },
    computed: {
        ...mapGetters(["openAd"]),
    },
    methods: {
        async handleBannerClick() {
            const adInfo = {
                zoneId: 95, // This is an example zoneId, please check your own code parameters
                publisherId: 69, // This is an example publisherId, please check your own code parameters
                eventId: 0, // Reserved Parameter
            };
            console.log("图片被点击:", this.imageUrl);

            const res = await window.openADJsSDK.bridge.click(adInfo);
            if (res.code === 0) {
                // Callback click info successful
                console.log("opad click", res);
            } else {
                // We recommend not throwing an error
            }
        },
        initAd() {
            const adInfo = {
                zoneId: 95,
                publisherId: 69,
                eventId: 0,
            };
            const bannerDiv = this.$refs.openADBanner;
            const img = new Image();
            img.src = this.openAd.bannerAd.resource_url;

            img.onload = async () => {
                bannerDiv.style.height = `${img.height * (bannerDiv.clientWidth / img.width)}px`;
                bannerDiv.style.backgroundImage = `url(${this.openAd.bannerAd.resource_url})`;
                bannerDiv.style.backgroundSize = "contain";
                bannerDiv.style.backgroundPosition = "center";
                bannerDiv.style.backgroundRepeat = "no-repeat";
                const res = await window.openADJsSDK.bridge.log(adInfo);
                // if (res.code === 0) {
                //     // Callback log info successful
                //     console.log("opad loaded", res);
                // } else {
                //     // We recommend not throwing an error
                // }
            };
        },
    },
    mounted() {
        console.log("openAd", this.openAd);

        if (this.openAd.bannerAd) {
            this.initAd();
        }
    },
};
</script>

<template>
    <div class="absolute left-0 top-0 h-full w-full bg-[#F2F2F7]">
        <div
            v-if="!isLoading"
            class="relative flex h-full w-full flex-col justify-between bg-[#F2F2F7]"
        >
            <!-- bg img -->
            <img
                :src="`/assets/imgs/views/wallet/${currentPlatform === 'telegram' ? 'wallet-bg.svg' : 'wallet-line-bg.svg'}`"
                alt=""
                class="absolute left-0 top-0 z-0 w-full object-contain"
            />
            <!-- logo & locales wrapper -->
            <div
                class="relative z-10 flex h-12 w-full flex-shrink-0 flex-grow-0 items-end justify-between px-6"
            >
                <!-- logo & locales content -->
                <div class="flex w-full items-center justify-between">
                    <!-- user profile -->
                    <div class="flex items-center gap-2">
                        <img
                            @error="
                                (e) =>
                                    (e.target.src =
                                        '/assets/imgs/utils/avatar/avatar_default.svg')
                            "
                            :src="
                                currentUserProfilePicUrl ||
                                '/assets/imgs/utils/avatar/avatar_default.svg'
                            "
                            alt="user_profile_picture"
                            class="h-8 w-8 rounded-full border border-[#fff]"
                        />
                        <span
                            class="max-w-[200px] truncate text-base font-bold text-[#111]"
                            >{{ currentUserFirstName }}</span
                        >
                    </div>
                    <!-- lang -->
                    <button
                        @click="() => $router.push({ name: 'language' })"
                        class="flex h-6 w-6 items-center justify-center"
                    >
                        <img
                            src="assets/imgs/icons/lang_icon.svg"
                            alt=""
                            class="h-full w-full"
                        />
                    </button>
                </div>
            </div>
            <!-- wallet content wrapper -->
            <div class="relative flex w-full flex-col gap-4 px-6 py-4">
                <!-- code before banner -->
                <!-- <div 
                    @click="handleBannerClick"
                    ref="openADBanner"
                    class="openADJsSDKBanner w-full" 
                    publisherld="69"
                    zoneld="95">
                </div> -->
                <!-- code after banner -->
                <PromotionBanner />
            </div>
            <!-- red envelope -->
            <div
                class="envelope relative min-h-[300px] w-full flex-shrink flex-grow flex-col rounded-t-3xl bg-[#3A1691] line:bg-black"
            >
                <!-- envelope cover -->
                <div
                    @click="sendGiftOnclick"
                    class="relative h-[calc(100%-44px)] w-full"
                >
                    <div
                        class="relative h-full w-full rounded-t-[20px]"
                        :style="{
                            backgroundImage: `url(/assets/imgs/views/wallet/${this.currentPlatform === 'telegram' ? '' : 'line-'}wallet-gift-cover.png)`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'bottom',
                            maskImage: `url(${
                                isLocalhost
                                    ? 'assets/imgs/utils/maskTemplate/template.svg'
                                    : '/appcenterstatic/template.svg'
                            })`,
                            maskSize: 'cover',
                            maskPosition: 'bottom',
                            maskRepeat: 'no-repeat',
                            WebkitMaskImage: `url(${
                                isLocalhost
                                    ? 'assets/imgs/utils/maskTemplate/template.svg'
                                    : '/appcenterstatic/template.svg'
                            })`,
                            WebkitMaskSize: 'cover',
                            WebkitMaskPosition: 'bottom',
                            WebkitMaskRepeat: 'no-repeat',
                        }"
                    ></div>
                    <!-- <img
                        :src="`/assets/imgs/views/wallet/${currentPlatform === 'telegram' ? '' : 'line-'}wallet-gift-cover.png`"
                        class="h-full w-full rounded-t-[1.25rem] object-cover object-bottom pb-6"
                    /> -->
                    <span
                        class="absolute left-1/2 top-1/3 -translate-x-1/2 -translate-y-1/2 text-center text-2xl font-bold text-[#fff]"
                        >{{ $t("index.sendGiftsToFriends") }}</span
                    >
                    <!-- sticker -->
                    <div
                        class="z-1 absolute bottom-[-20px] left-1/2 h-16 w-[208px] -translate-x-1/2 rounded-[10px] p-1 hover:cursor-pointer"
                        :style="stickerOuterBgStyle"
                    >
                        <div
                            class="flex h-full w-full items-center justify-center rounded-lg bg-secondary"
                            :style="stickerInnerShadowStyle"
                        >
                            <span
                                class="text-center text-lg font-bold text-[#fff]"
                                >{{ $t("index.sendAGiftNow") }}</span
                            >
                        </div>
                    </div>
                </div>
                <!-- gift records button -->
                <button
                    @click.stop="giftRecordsOnclick"
                    class="absolute right-2 top-1 flex h-10 w-10 items-center justify-center text-2xl"
                >
                    <svg-icon
                        type="mdi"
                        :path="mdiHistory"
                        :size="24"
                        class="text-[#fff]"
                    ></svg-icon>
                </button>
            </div>
        </div>
        <Loading v-else :full-screen="true" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiHistory } from "@mdi/js";
import TokenDropdown from "@/components/TokenDropdown.vue";
import TokenSelect from "@/components/TokenSelect.vue";
import PromotionBanner from "./components/PromotionBanner.vue";
import Loading from "@/components/Loading.vue";

export default {
    name: "Index",
    components: {
        SvgIcon,
        TokenDropdown,
        TokenSelect,
        PromotionBanner,
        Loading,
    },
    data: () => ({
        isLoading: false,
        mdiHistory,
        giftBgStyle:
            "background: radial-gradient(92.33% 50% at 50% 56.79%,#3c2ba9 0%,#31006f 100%);",
        stickerOuterBgStyle:
            "background: linear-gradient(95deg,#ffdb69 3.5%,#fee7b0 20.57%,#fee9ba 26.27%,#fdecd0 28.09%,#feda92 31.52%,#ffcd64 34.37%,#ffc853 35.8%,#ff8514 98.36%);",
        stickerInnerShadowStyle:
            "box-shadow: 2px 0px 6px 0px rgba(255, 0, 0, 0.24) inset;",
    }),
    computed: {
        ...mapGetters(["platform", "userInfo"]),
        isLocalhost() {
            return window.location.hostname === "localhost";
        },
        currentPlatform() {
            return this.platform;
        },
        currentUserProfilePicUrl() {
            return (
                (this.userInfo &&
                    this.userInfo.user &&
                    this.userInfo.user.profilePhoto) ||
                null
            );
        },
        currentUserFirstName() {
            return (
                (this.userInfo &&
                    this.userInfo.user &&
                    this.userInfo.user.tg_info &&
                    this.userInfo.user.tg_info.first_name) ||
                null
            );
        },
    },
    methods: {
        async updateUserInfo() {
            this.isLoading = true;
            try {
                const err = await this.$store.dispatch("updateUserInfo");
                if (err) {
                    throw new Error(JSON.stringify(err));
                }
            } catch (err) {
                window.Telegram.WebApp.showAlert(err);
            }
            this.isLoading = false;
        },
        sendGiftOnclick() {
            this.$router.push({ name: "send-gifts" });
        },
        giftRecordsOnclick() {
            this.$router.push({ name: "gift-records" });
        },
        thirdPartyTransferOnClick() {
            this.$router.push({
                name: "withdraw",
                query: {
                    method: "thirdPartyTransfer",
                    token: this.currentTokenName,
                },
            });
        },
        depositOnclick() {
            // window.Telegram.WebApp.showAlert("Coming soon!");
            this.$router.push({ name: "deposit" });
        },
        earnCreditTokenBtnOnClick() {
            this.$router.push({ name: "earn-credits" });
        },
    },
    created() {
        this.updateUserInfo();
    },
};
</script>

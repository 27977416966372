var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[(_vm.isActive)?_c('div',{staticClass:"fixed left-0 top-0 z-10 h-screen w-screen p-6"},[_c('div',{staticClass:"absolute left-0 top-0 z-[-1] h-full w-full bg-[#000] bg-opacity-60",on:{"click":_vm.close}}),_c('div',{staticClass:"relative left-0 top-0 flex h-full w-full rounded-xl",class:_vm.currentPlatform === 'telegram'
                    ? 'bg-gradient-to-t from-[#FFE5ED] to-[#fff]'
                    : 'bg-gradient-to-b from-white to-[#fffad6]'},[_c('img',{staticClass:"absolute left-0 top-0 z-0 w-full pt-10",attrs:{"src":"assets/imgs/views/receiveGifts/invitationPanel_qrCodeBg.svg","alt":""}}),_c('div',{staticClass:"relative z-10 flex w-full flex-col items-center"},[_c('div',{staticClass:"flex w-full justify-center p-2",class:_vm.currentPlatform === 'telegram'
                            ? 'bg-[#ff5d8f29]'
                            : 'bg-[#ffe10029]'},[_c('span',{staticClass:"text-center text-sm",class:_vm.currentPlatform === 'telegram'
                                ? 'text-[#FF5D8F]'
                                : 'text-black'},[_vm._v(_vm._s(_vm.$t( "receiveGifts.qrCodeModal.screenshotHintText", )))])]),_c('div',{staticClass:"mt-6 flex flex-col items-center gap-2"},[_c('img',{staticClass:"h-10 w-10 rounded-full",attrs:{"src":_vm.currentUserProfilePicUrl ||
                            '/assets/imgs/utils/avatar/avatar_default.svg',"alt":"user_profile_picture"},on:{"error":(e) =>
                                (e.target.src =
                                    '/assets/imgs/utils/avatar/avatar_default.svg')}}),_c('span',{staticClass:"text-center text-base font-bold text-[#111]"},[_vm._v(_vm._s(_vm.$t("receiveGifts.qrCodeModal.giftTitleText", { sender: _vm.currentUserName, })))])]),_c('div',{staticClass:"relative mt-5 flex w-full max-w-[240px] flex-grow flex-col items-center overflow-hidden rounded-[20px]",style:({ backgroundColor: _vm.giftBgColor })},[_c('div',{staticClass:"relative h-full w-full rounded-t-3xl",style:({
                            backgroundImage: `url(https://www.tongifts.app/static/tg_gift_img/cover_${_vm.isForNewUser ? 'newdefault' : _vm.giftCoverId}.png)`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'bottom',
                            maskImage: `url(${
                                _vm.isLocalhost
                                    ? 'assets/imgs/utils/maskTemplate/template.svg'
                                    : '/appcenterstatic/template.svg'
                            })`,
                            maskSize: 'cover',
                            maskPosition: 'bottom',
                            maskRepeat: 'no-repeat',
                            WebkitMaskImage: `url(${
                                _vm.isLocalhost
                                    ? 'assets/imgs/utils/maskTemplate/template.svg'
                                    : '/appcenterstatic/template.svg'
                            })`,
                            WebkitMaskSize: 'cover',
                            WebkitMaskPosition: 'bottom',
                            WebkitMaskRepeat: 'no-repeat',
                        })}),_c('div',{staticClass:"relative bottom-[72px] flex h-[100px] w-[100px] items-center justify-center"},[_c('vue-qr',{attrs:{"text":_vm.targetAddress,"margin":6,"size":112}}),_c('div',{staticClass:"absolute left-1/2 top-1/2 flex h-5 w-5 -translate-x-1/2 -translate-y-1/2 items-center justify-center bg-[#fff]"},[_c('img',{staticClass:"w-[14px]",attrs:{"src":"assets/imgs/utils/logo/ton_gifts_logo.svg","alt":""}})])],1)]),_c('span',{staticClass:"mt-6 px-6 text-center text-sm text-[#777]"},[_vm._v(_vm._s(_vm.$t("receiveGifts.qrCodeModal.tgPromotionText", { botName: _vm.TGBotName, })))]),_c('div',{staticClass:"mb-6 mt-4 flex items-center gap-3 rounded-lg bg-[#30A3E6] py-1 pl-3 pr-1"},[_c('span',{staticClass:"text-sm text-[#fff]"},[_vm._v(_vm._s(_vm.$t("receiveGifts.qrCodeModal.onTelegram")))]),_c('div',{staticClass:"flex items-center gap-1 rounded bg-[#fff] px-2 py-[2px]"},[_c('img',{staticClass:"h-4 w-4",attrs:{"src":"assets/imgs/icons/invitationPanel_search.svg","alt":""}}),_c('span',{staticClass:"text-xs font-bold text-[#111]"},[_vm._v(_vm._s(_vm.TGBotName))])])])]),_c('button',{staticClass:"absolute bottom-0 left-1/2 z-10 flex h-6 w-6 -translate-x-1/2 translate-y-1/2 items-center justify-center rounded-full bg-[#fff] text-base text-[#aaa]",on:{"click":_vm.close}},[_vm._v(" × ")])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div
        class="flex items-center justify-center"
        :class="{ 'fixed left-0 top-0 h-screen w-screen': fullScreen }"
    >
        <img
            :src="`/assets/imgs/utils/logo/${currentPlatform === 'telegram' ? 'ton' : 'kaia'}_gifts_logo.svg`"
            alt=""
            class="w-[60px] animate-ping"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "Loading",
    props: {
        fullScreen: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters(["platform"]),
        currentPlatform() {
            return this.platform;
        },
    },
};
</script>

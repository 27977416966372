import { apiHelper } from "@/utils/requestHelper.js";

export default {
    getPrizeList() {
        return apiHelper.get("/spin/config");
    },
    spinPrize() {
        return apiHelper.post("/spin/spin");
    },
};

import Vue from "vue";
import Vuex from "vuex";
import userAPI from "@/apis/user";
import envelopeAPI from "@/apis/envelope";
import localGifts from "@/json/gifts.json";
import appCenter from "@/apis/appCenter";
import spinApi from "@/apis/spin";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        platform: "telegram", // telegram || line
        userInfo: null,
        currentConnectedWalletAddress: "",
        currentToken: null,
        tokenList: [],
        launchMethod: "BOT", // BOT or Miniapp
        gifts: [], //Gifts Config
        discover: null,
        openAd: {
            bannerAd: null,
            interactiveAd: null,
        },
        prizeList: [],
    },
    getters: {
        userInfo: (state) => state.userInfo,
        currentConnectedWalletAddress: (state) =>
            state.currentConnectedWalletAddress,
        launchMethod: (state) => state.launchMethod,
        currentToken: (state) => state.currentToken,
        currentTokenName: (state) =>
            state && state.currentToken && state.currentToken._id
                ? state.currentToken._id
                : localStorage.getItem("token"),
        tokenList: (state) => state.tokenList,
        gifts: (state) => state.gifts,
        currentBalance: (state) => {
            const currentTokenName =
                (state.currentToken && state.currentToken._id) ||
                localStorage.getItem("token");
            return state.userInfo &&
                state.userInfo.balance &&
                state.userInfo.balance[currentTokenName]
                ? Number(state.userInfo.balance[currentTokenName].balance)
                : 0;
        },
        currentCreditsTokenName: (state) => {
            const creditTokens =
                state.tokenList &&
                state.tokenList.filter((token) => token.isCredits);
            const targetTokenName = creditTokens[0] && creditTokens[0]._id;
            return targetTokenName || "";
        },
        currentCreditsBalance: (state) => {
            const creditTokens =
                state.tokenList &&
                state.tokenList.filter((token) => token.isCredits);
            const targetTokenName = creditTokens[0] && creditTokens[0]._id;
            if (targetTokenName) {
                return state.userInfo &&
                    state.userInfo.balance &&
                    state.userInfo.balance[targetTokenName]
                    ? Number(state.userInfo.balance[targetTokenName].balance)
                    : 0;
            } else {
                return 0;
            }
        },
        discover: (state) => state.discover,
        platform: (state) => state.platform,
        openAd: (state) => state.openAd,
        prizeList: (state) => state.prizeList,
    },
    mutations: {
        setPlatform(state, platform) {
            state.platform = platform;
        },
        // 登入
        setUserInfo(state, userInfo) {
            console.log("set user info");
            state.userInfo = {
                ...state.userIfo,
                ...userInfo,
            };
        },
        // 連接錢包成功
        setCurrentConnectedWalletAddress(state, walletAddress) {
            console.log("set currentConnectedWalletAddress");
            state.currentConnectedWalletAddress = walletAddress;
        },
        // openlink: MINIAPP;  bot:BOT
        setLaunchMethod(state, _launchMethod) {
            console.log("set launchMethod");
            state.launchMethod = _launchMethod;
        },
        // 切換幣種
        setCurrentToken(state, token) {
            state.currentToken = { ...token };
            localStorage.setItem("currentToken", token._id); // set token name
        },
        // 置入 token 列表資料
        setTokenList(state, tokenList) {
            state.tokenList = [...tokenList];
        },

        // 置入 token 列表資料
        setGifts(state, gifts) {
            state.gifts = [...gifts];
        },
        setDiscover(state, discover) {
            state.discover = {
                ...state.discover,
                ...discover,
            };
        },
        setOpenAd(state, openAd) {
            state.openAd = {
                ...state.openAd,
                ...openAd,
            };
        },
        setPrizeList(state, prizeList) {
            state.prizeList = {
                ...state.prizeList,
                ...prizeList,
            };
        },
    },
    actions: {
        async updateInitDataUserInfo({ commit, state }) {
            try {
                const initDataUnsafe = Telegram.WebApp.initDataUnsafe; //await userAPI.getUserInfo();
                // if (res.status !== 200) {
                //     const errMessage = res.data;
                //     throw new Error(errMessage);
                // }
                const userInfo = {
                    balance: {},
                    user: {
                        _id: `${initDataUnsafe && initDataUnsafe.user && initDataUnsafe.user.id}`,
                        username:
                            (initDataUnsafe &&
                                initDataUnsafe.user &&
                                initDataUnsafe.user.username) ||
                            "",
                        tg_info: initDataUnsafe && initDataUnsafe.user,
                    },
                };
                console.log(
                    "updateInitDataUserInfo initDataUnsafe: ",
                    initDataUnsafe,
                );
                commit("setUserInfo", userInfo);
                return null;
            } catch (err) {
                console.log("updateInitDataUserInfo err: ", err);
                return err;
            }
        },
        async updateUserInfo({ commit, state }) {
            try {
                const [userInfoRes, inviteLinkRes, referralRes] =
                    await Promise.all([
                        userAPI.getUserInfo(),
                        envelopeAPI.getInvitationGift(),
                        userAPI.getUserReferrals(),
                    ]);
                if (
                    userInfoRes.status !== 200 ||
                    inviteLinkRes.status !== 200 ||
                    referralRes.status !== 200
                ) {
                    const errMessage =
                        userInfoRes.statusText ||
                        inviteLinkRes.statusText ||
                        referralRes.statusText ||
                        "Failed to fetch user data";
                    throw new Error(errMessage);
                }
                userInfoRes.data.user["inviteLink"] = inviteLinkRes.data;
                userInfoRes.data.user["referrals"] = referralRes.data;
                commit("setUserInfo", userInfoRes.data);
                return null;
            } catch (err) {
                console.error("updateUserInfo err: ", err);
                return err;
            }
        },

        async updateGifts({ commit, state }) {
            try {
                const res = await envelopeAPI.getGifts();
                if (res.status !== 200) {
                    const errMessage = res.data;
                    throw new Error(errMessage);
                }
                const gifts = res.data;
                commit("setGifts", gifts);
                return null;
            } catch (err) {
                console.log("setGifts err: ", err);
                // 請求獲取不到時使用本地端的靜態資源
                const gifts = localGifts;
                commit("setGifts", gifts);
                return err;
            }
        },
        async updateDiscover({ commit }) {
            try {
                const res = await appCenter.getAppCenter();
                if (res.status !== 200) {
                    throw new Error(res.data);
                }
                commit("setDiscover", res.data);
            } catch (err) {
                console.error("updateDiscover err: ", err);
                return err;
            }
        },
        async updateInitLineUserInfo({ commit }, data) {
            try {
                const userInfo = {
                    balance: {},
                    user: {
                        _id: data?.userId ?? "",
                        username: data?.displayName ?? "",
                        line_info: data,
                    },
                };
                commit("setUserInfo", userInfo);
                return null;
            } catch (err) {
                console.log("updateInitLineUserInfo err: ", err);
                return err;
            }
        },
        async updateOpenAd({ commit, state }) {
            const adParams = {
                version: "1", // optional
                // TG     : { type, FN }, // required, TG settings
                // if you've loaded 'https://telegram.org/is/telegram-web-app.js' in your TMA,
                TG: { type: "telegram", FN: window.Telegram },
                // if you're using '@telegram-apps/sdk' in your TMA,
                //    import { initUtils, retrieveLaunchParams } from "@telegram-apps/sdk";
                //    TG: { type: '@telegram-apps/sdk', FN: { initUtils, retrieveLaunchParams } };
                // if you haven't loaded any Telegram SDK,
                //    TG: { type: 'noSDK', FN: null }
            };
            const adInfo = {
                zoneId: 95, // This is an example zoneId, please check your own code parameters
                publisherId: 69, // This is an example publisherId, please check your own code parameters
                eventId: 0, // Reserved Parameter
            };
            // Create a userInfo object. Regardless of whether the user is logged in to your application or not, the following parameters must be send to SDK.
            // const userInfo = {
            //     // Please add reasonable user IDs for your end users.
            //     // Otherwise, OpenAD statistics may be inaccurate
            //     // which may affect your incentives
            //     userId: state.userInfo?.user?.tg_info?.id, // user Id , If there is no data, please leave it blank
            //     firstName: state.userInfo?.user?.tg_info?.first_name, // firstName or userId , If there is no data, please leave it blank
            //     lastName: state.userInfo?.user?.tg_info?.last_name, // lastName or userId , If there is no data, please leave it blank
            //     userName: state.userInfo?.user?.tg_info?.username, // username or userId , If there is no data, please leave it blank
            // };
            try {
                // const [bannerRes, interactiveRes] = await Promise.all([
                //     window.openADJsSDK.bridge.get({
                //         adParams,
                //         userInfo: {},
                //         adInfo,
                //     }),
                //     // window.openADJsSDK.interactive.init({
                //     //     adParams,
                //     //     adInfo,
                //     //     userInfo,
                //     // }),
                // ]);
                const bannerRes = await window.openADJsSDK.bridge.get({
                    adParams,
                    userInfo: {},
                    adInfo,
                });

                commit("setOpenAd", {
                    bannerAd: bannerRes.data,
                });
                console.log("UPDATE AD OK", bannerRes.data);

                // if (interactiveRes && interactiveRes.code === 0) {
                //     const callbackFunc = {
                //         // Indicates load ad resource from OpenAD platform, false will be returned if there is no resource to be loaded for the publisher slot/zone
                //         adResourceLoad: (e) => {
                //             // 'step1', e = ture / false
                //             console.log("adResourceLoad", e);
                //         },
                //         // Indicates the interactive ad is opening
                //         adOpening: (e) => {
                //             // 'step2', e = ture / false
                //             console.log("adOpening", e);
                //         },
                //         // Indicates the interactive ad is opened,
                //         adOpened: (e) => {
                //             // 'step3',  e = ture / false
                //         },
                //         // indicates the interactive ad task is finished, the task is defined by publisher
                //         adTaskFinished: (e) => {
                //             // 'step5',  e = viewAD / click
                //         },
                //         // Indicates the interactive ad is closing
                //         adClosing: (e) => {
                //             // 'step6', e = viewAD / click
                //         },
                //         // Indicates the interactive ad is closed
                //         adClosed: (e) => {
                //             // 'step7', e = viewAD / click
                //             // If you want to perform different steps based on different shutdown states, please write the code here.
                //         },
                //         // Indicates clicked and jumps
                //         adClick: (e) => {
                //             // 'step4', e = ture / false
                //         },
                //     };
                //     window.openADJsSDK.interactive.getRender({
                //         adInfo,
                //         cb: callbackFunc,
                //     });
                // }
            } catch (err) {
                console.error("updateOpenAd err: ", err);
                return err;
            }
        },
        async updatePrizeList({ commit }) {
            try {
                const res = await spinApi.getPrizeList();
                if (res.status !== 200) {
                    throw new Error(res.data);
                }
                commit("setPrizeList", res.data);
            } catch (err) {
                console.error("updatePrizeList err: ", err);
                return err;
            }
        },
    },
    modules: {},
});

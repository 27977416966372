<template>
    <div>
        <div
            v-if="todayCheckInConfig && isProgressInit"
            @click="dailyCheckInTaskOnClick"
            class="flex h-14 w-full items-center gap-3 rounded-2xl bg-[#fff] px-4"
        >
            <!-- calendar image -->
            <img
                src="assets/imgs/views/dailyCheckIn/calendar.png"
                alt=""
                class="h-10 w-10"
            />
            <!-- text wrapper -->
            <div class="flex flex-grow flex-col gap-1">
                <span class="text-sm font-bold leading-4 text-[#111]">{{
                    $t("earnCredits.dailyCheckInTaskTitleText")
                }}</span>
                <div class="flex items-center gap-1">
                    <img
                        @error="
                            (e) =>
                                (e.target.src =
                                    '/assets/imgs/utils/token/default.png')
                        "
                        :src="`assets/imgs/utils/token/${todayCheckInConfig ? todayCheckInConfig.onChain.token : ''}.png`"
                        alt="credits_point"
                        class="h-4 w-4"
                    />
                    <span class="text-xs text-secondary">{{
                        `+${todayCheckInTotalRewardAmount}`
                    }}</span>
                </div>
            </div>
            <!-- claim status -->
            <div
                v-if="isTodayCheckInCompleted"
                class="flex h-6 w-6 items-center justify-center rounded-full bg-[#E3FFE9]"
            >
                <svg-icon
                    type="mdi"
                    :path="mdiCheck"
                    :size="20"
                    class="text-[#00DD31]"
                ></svg-icon>
            </div>
            <div
                v-else
                class="flex h-8 min-w-20 items-center justify-center whitespace-nowrap rounded-lg bg-secondary px-4 text-center text-sm font-bold text-[#fff]"
            >
                {{ $t("earnCredits.claimBtnText") }}
            </div>
        </div>
        <div
            v-else
            class="h-14 w-full animate-pulse rounded-2xl bg-[#fff]"
        ></div>
    </div>
</template>

<script>
import userAPI from "@/apis/user";
import { getPrecisionValue } from "@/utils/textFormat";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCheck } from "@mdi/js";
import moment from "moment";

export default {
    name: "DailyCheckInTask",
    components: {
        SvgIcon,
    },
    data: () => ({
        mdiCheck,
        isProgressInit: false,
        // 全部簽到 config 的 array
        checkinConfig: null,
        // 全部紀錄
        records: null,
        // 包含 pending 紀錄的 raw 簽到紀錄
        continuousRecords: null,
    }),
    computed: {
        // 根據不同天的 id 分組的紀錄 (過濾掉了 pending 中的紀錄)
        dailyGroupedRecords() {
            const records = this.records;
            return records.reduce((acc, currentRecord) => {
                // 過濾掉沒有完成的紀錄
                if (currentRecord.status !== 1) {
                    return acc;
                }
                // 尋找id 相同並且 utc 時間同一天的紀錄
                const sameDateSameIdItem = acc.find((accItem) => {
                    const accItemConfigId = accItem.id;
                    // 取整合後的物件當中的任一紀錄
                    const accItemExistRecord =
                        accItem["default"] || accItem["onChain"];
                    const accItemExistRecordTime =
                        accItemExistRecord &&
                        accItemExistRecord.createdAt &&
                        moment.utc(accItemExistRecord.createdAt);
                    const currentItemTime =
                        currentRecord &&
                        currentRecord.createdAt &&
                        moment.utc(currentRecord.createdAt);
                    const currentItemConfigId =
                        currentRecord.data &&
                        currentRecord.data.config &&
                        currentRecord.data.config._id;
                    return (
                        accItemConfigId === currentItemConfigId &&
                        accItemExistRecordTime.isSame(currentItemTime, "day")
                    );
                });
                // 如果找到了相同id 又相同utc日期的紀錄，就加進去
                if (sameDateSameIdItem) {
                    sameDateSameIdItem[currentRecord.type] = currentRecord;
                    // 沒有的話就新增一個單一紀錄分組
                } else {
                    const newItem = {
                        id:
                            currentRecord.data &&
                            currentRecord.data.config &&
                            currentRecord.data.config._id,
                    };
                    newItem[currentRecord.type] = currentRecord;
                    acc.push(newItem);
                }
                return acc;
            }, []);
        },
        // 到當天為止有效的連續簽到紀錄
        validContinuousDailyGroupedRecords() {
            // 如果根本沒有紀錄就返回空陣列
            if (!this.records || !this.records.length) return [];
            // 如果最新一次紀錄不在昨天以後，表示連續簽到已經中斷了
            const latestRecord = this.records[this.records.length - 1];
            const latestRecordTime =
                latestRecord &&
                latestRecord.createdAt &&
                moment(latestRecord.createdAt);
            const lastDayStartTime = moment()
                .utc()
                .startOf("day")
                .add(-1, "day");
            const isContinuous = latestRecordTime > lastDayStartTime;
            const continuousDays = latestRecord.streak;
            console.log("isContinuous: ", isContinuous);
            console.log("latestRecord: ", latestRecord);
            console.log("continuousDays: ", continuousDays);
            console.log(
                "validContinuousDailyGroupedRecords: ",
                this.dailyGroupedRecords.slice(-1 * continuousDays),
            );
            //  如果昨天有連續簽到紀錄，根據昨天的連續簽到紀錄 streak 取最後有效的幾組紀錄
            return isContinuous
                ? this.dailyGroupedRecords.slice(-1 * continuousDays)
                : [];
        },
        // 當前連續簽到天數
        currentContinuousDays() {
            return (
                this.validContinuousDailyGroupedRecords &&
                this.validContinuousDailyGroupedRecords.length
            );
        },
        // 當天普通簽到是否已經完成
        isTodayDefaultCheckInCompleted() {
            const latestValidContinuousDailyGroupedRecords =
                this.validContinuousDailyGroupedRecords &&
                this.validContinuousDailyGroupedRecords[
                    this.validContinuousDailyGroupedRecords.length - 1
                ];
            const latestValidContinuousDailyRecord =
                latestValidContinuousDailyGroupedRecords &&
                latestValidContinuousDailyGroupedRecords.default;
            const latestValidContinuousDailyRecordTime =
                latestValidContinuousDailyRecord &&
                moment(latestValidContinuousDailyRecord.createdAt);
            const todayStartTime = moment().utc().startOf("day");
            return (
                latestValidContinuousDailyRecordTime &&
                latestValidContinuousDailyRecordTime > todayStartTime
            );
        },
        // 當天錢包簽到是否已經完成
        isTodayOnChainCheckInCompleted() {
            const latestValidContinuousDailyGroupedRecords =
                this.validContinuousDailyGroupedRecords &&
                this.validContinuousDailyGroupedRecords[
                    this.validContinuousDailyGroupedRecords.length - 1
                ];
            const latestValidContinuousDailyRecord =
                latestValidContinuousDailyGroupedRecords &&
                latestValidContinuousDailyGroupedRecords.onChain;
            const latestValidContinuousDailyRecordTime =
                latestValidContinuousDailyRecord &&
                moment(latestValidContinuousDailyRecord.createdAt);
            const todayStartTime = moment().utc().startOf("day");
            return (
                latestValidContinuousDailyRecordTime &&
                latestValidContinuousDailyRecordTime > todayStartTime
            );
        },
        // 當天簽到是否全數完成
        isTodayCheckInCompleted() {
            return (
                this.isTodayDefaultCheckInCompleted &&
                this.isTodayOnChainCheckInCompleted
            );
        },
        // 當天的簽到 config
        todayCheckInConfig() {
            const isTodayRecordExist =
                this.isTodayDefaultCheckInCompleted ||
                this.isTodayOnChainCheckInCompleted;
            return isTodayRecordExist
                ? this.checkinConfig &&
                      this.checkinConfig[this.currentContinuousDays - 1]
                : this.checkinConfig &&
                      this.checkinConfig[this.currentContinuousDays];
        },
        // 當天全部獎勵加總
        todayCheckInTotalRewardAmount() {
            const tokenName =
                this.todayCheckInConfig &&
                this.todayCheckInConfig.default &&
                this.todayCheckInConfig.default.token;
            const defaultRewardAmount = Number(
                (this.todayCheckInConfig &&
                    this.todayCheckInConfig.default &&
                    this.todayCheckInConfig.default.amount) ||
                    0,
            );
            const onChainRewardAmount = Number(
                (this.todayCheckInConfig &&
                    this.todayCheckInConfig.onChain &&
                    this.todayCheckInConfig.onChain.amount) ||
                    0,
            );
            const totalAmount = defaultRewardAmount + onChainRewardAmount;
            return getPrecisionValue(tokenName, totalAmount);
        },
    },
    methods: {
        async getCheckinConfig() {
            try {
                const res = await userAPI.getCheckinConfig();
                if (res.status !== 200) throw new Error();
                console.log("getCheckinConfig res: ", res);
                const config = res.data;
                if (res.data) {
                    this.checkinConfig = config;
                    // 獲取當前簽到進度
                    this.getCheckinProgress();
                }
            } catch (err) {
                console.log("getCheckinConfig err: ", err);
            }
        },
        async getCheckinProgress() {
            try {
                const res = await userAPI.getCheckinProgress();
                if (res.status !== 200) throw new Error();
                const reversedRecords = res.data;
                const records = reversedRecords.reverse();
                this.records = records;
            } catch (err) {
                console.log("getCheckinProgress err: ", err);
            }
            this.isProgressInit = true;
        },
        dailyCheckInTaskOnClick() {
            this.$router.push({ name: "daily-check-in" });
        },
    },
    created() {
        this.getCheckinConfig();
    },
};
</script>

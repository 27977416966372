<template>
    <div class="absolute left-0 top-0 h-full w-full">
        <div
            v-if="!isLoading && currentTokenName"
            class="flex h-full w-full flex-col bg-[#F2F2F7]"
        >
            <!-- user info row -->
            <div
                class="flex h-14 min-h-14 w-full items-center justify-between px-6"
            >
                <!-- user profile -->
                <div class="flex items-center gap-2">
                    <img
                        @error="
                            (e) =>
                                (e.target.src =
                                    '/assets/imgs/utils/avatar/avatar_default.svg')
                        "
                        :src="
                            currentUserProfilePicUrl ||
                            '/assets/imgs/utils/avatar/avatar_default.svg'
                        "
                        alt="user_profile_picture"
                        class="h-8 w-8 rounded-full border border-[#fff]"
                    />
                    <span
                        class="max-w-[200px] truncate text-base font-bold text-[#111]"
                        >{{ $t("assets.titleText") }}</span
                    >
                </div>
                <!-- connect wallet button -->
                <button
                    @click="connectWalletBtnOnClick"
                    class="flex h-8 items-center justify-center gap-1 rounded-lg bg-[#0088CC] px-4 text-center"
                >
                    <img
                        src="assets/imgs/views/dailyCheckIn/logo_ton.svg"
                        alt=""
                        class="h-3 w-3"
                    />
                    <span class="text-xs font-bold text-[#fff]">{{
                        currentConnectedWalletAddress
                            ? slicedConnectedWalletAddress
                            : $t("assets.connectWalletBtnText")
                    }}</span>
                </button>
            </div>
            <!-- content -->
            <div
                class="flex h-[calc(100%-56px)] w-full flex-shrink flex-grow flex-col gap-2 rounded-t-2xl bg-[#fff] px-6 py-4"
            >
                <!-- upper content -->
                <div class="flex w-full flex-col gap-3">
                    <!-- balance wrapper -->
                    <div class="flex w-full flex-col">
                        <!-- balance row -->
                        <div class="flex w-full items-center justify-between">
                            <div class="flex items-end gap-2">
                                <img
                                    @error="
                                        (e) =>
                                            (e.target.src =
                                                '/assets/imgs/utils/token/default.png')
                                    "
                                    class="size-10"
                                    :src="`assets/imgs/utils/token/${currentTokenName}.png`"
                                    alt="token-image"
                                />
                                <span
                                    class="text-[32px] font-bold leading-10 text-[#111]"
                                    >{{ currentBalanceText }}</span
                                >
                            </div>
                            <img
                                @click="
                                    () =>
                                        this.$router.push({
                                            name: 'wallet-record',
                                        })
                                "
                                src="/assets/imgs/icons/assets_list.svg"
                                alt=""
                                class="h-6 w-6"
                            />
                        </div>
                        <!-- update row -->
                        <button
                            @click="() => updateBalance(false)"
                            :disabled="isFetching"
                            class="flex items-center gap-1 bg-transparent"
                        >
                            <!-- updated time -->
                            <span
                                v-show="balanceUpdatedTime !== null"
                                class="text-xs text-[#aaa]"
                                >{{ balanceUpdatedTime }}</span
                            >
                            <svg-icon
                                type="mdi"
                                :path="mdiRefresh"
                                size="20"
                                class="text-[#9699B5]"
                                :class="{ 'animate-spin': isFetching }"
                            ></svg-icon>
                        </button>
                    </div>
                    <!-- withdraw & deposit wrapper -->
                    <div class="flex w-full items-center gap-3">
                        <!-- Withdraw btn -->
                        <button
                            @click="
                                () =>
                                    this.$router.push('/select-token/withdraw')
                            "
                            :disabled="!filterTokenList.cantWithdraw"
                            class="flex h-10 w-1/2 items-center justify-center rounded-lg bg-[#F0F0F0] px-6 text-base font-bold text-secondary"
                            :class="{
                                'opacity-30': !filterTokenList.cantWithdraw,
                            }"
                        >
                            {{ $t("assets.withdrawBtnText") }}
                        </button>
                        <!-- Transfer btn -->
                        <!-- <button
                            @click="
                                () =>
                                    this.$router.push('/select-token/withdraw')
                            "
                            :disabled="!filterTokenList.cantTransfer"
                            class="flex h-10 w-1/2 items-center justify-center rounded-lg bg-[#F0F0F0] px-6 text-base font-bold text-secondary"
                            :class="{
                                'opacity-30': !filterTokenList.cantTransfer,
                            }"
                        >
                            {{ $t("index.transfer") }}
                        </button> -->
                        <!-- Deposit btn -->
                        <button
                            @click="
                                () => this.$router.push('/select-token/deposit')
                            "
                            :disabled="!filterTokenList.cantDeposit"
                            class="flex h-10 w-1/2 items-center justify-center rounded-lg bg-secondary px-6 text-base font-bold text-[#FFF]"
                            :class="{
                                'opacity-30': !filterTokenList.cantDeposit,
                            }"
                        >
                            {{ $t("assets.depositBtnText") }}
                        </button>
                    </div>
                </div>
                <!-- lower content -->
                <div class="no-scrollbar w-full flex-grow overflow-scroll">
                    <!-- tokens wrapper -->
                    <div class="flex w-full flex-col">
                        <a-tabs
                            class="token-tabs [&_.ant-tabs-ink-bar]:bg-primary [&_.ant-tabs-tab-active]:!text-primary [&_.ant-tabs-tab:hover]:!text-primary"
                            default-active-key="all"
                        >
                            <a-tab-pane
                                v-for="tab in tokenTabs"
                                :key="tab.key"
                                v-if="
                                    filterTokenList[tab.key] &&
                                    filterTokenList[tab.key].length
                                "
                                :tab="tab.name"
                            >
                                <TokenItem
                                    v-for="token in filterTokenList[tab.key]"
                                    :key="token.id"
                                    @selectToken="selectToken(token.name)"
                                    :image="token.image"
                                    :name="token.name"
                                    :balance="token.balance"
                                    :onChainInfo="token.onChainInfo"
                                >
                                    <template #3rdParty-transfer-btn>
                                        <button
                                            v-if="transferConfig(token)"
                                            @click.stop="
                                                thirdPartyTransferOnClick(token)
                                            "
                                            class="flex items-center"
                                        >
                                            <span class="text-xs text-primary">
                                                {{
                                                    $t(
                                                        "assets.thirdPartTransferBtnText",
                                                        {
                                                            thirdPartyName:
                                                                transferConfig(
                                                                    token,
                                                                ).name,
                                                        },
                                                    )
                                                }}
                                            </span>
                                            <svg-icon
                                                type="mdi"
                                                :path="mdiChevronRight"
                                                size="14"
                                                class="text-sm text-primary"
                                            ></svg-icon>
                                        </button>
                                    </template>
                                </TokenItem>
                            </a-tab-pane>
                        </a-tabs>
                    </div>
                    <OpenAd class="mt-7" />
                </div>
            </div>
        </div>
        <Loading v-else :full-screen="true" />
    </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import OpenAd from "@/components/OpenAd.vue";
import TokenItem from "@/components/TokenItem.vue";
import asset from "@/configs/asset";
import { handleSelectToken } from "@/utils/common";
import { getPrecisionValue } from "@/utils/textFormat";
import { handleTransferConfig } from "@/utils/thirdParty";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiChevronRight, mdiRefresh } from "@mdi/js";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
    name: "Assets",
    components: {
        Loading,
        SvgIcon,
        TokenItem,
        OpenAd,
    },
    data: () => ({
        mdiRefresh,
        mdiChevronRight,
        isLoading: false,
        isFetching: false,
        balanceUpdatedTime: null,
        tokenTabs: asset.tokenTabs,
    }),
    computed: {
        ...mapGetters([
            "currentConnectedWalletAddress",
            "currentToken",
            "currentTokenName",
            "currentBalance",
            "tokenList",
            "userInfo",
        ]),
        currentUserProfilePicUrl() {
            return (
                (this.userInfo &&
                    this.userInfo.user &&
                    this.userInfo.user.profilePhoto) ||
                null
            );
        },
        slicedConnectedWalletAddress() {
            return this.currentConnectedWalletAddress &&
                this.currentConnectedWalletAddress.length > 6
                ? `${this.currentConnectedWalletAddress.slice(0, 3)}...${this.currentConnectedWalletAddress.slice(-3)}`
                : this.currentConnectedWalletAddress;
        },

        currentBalanceText() {
            return getPrecisionValue(
                this.currentTokenName,
                this.currentBalance,
            );
        },
        filterTokenList() {
            const allTokens =
                this.tokenList && this.tokenList.length > 0
                    ? this.tokenList
                          .map((token) => {
                              return {
                                  ...token,
                                  id: token._id,
                                  name: token._id,
                                  balance:
                                      getPrecisionValue(
                                          token._id,
                                          this.userInfo &&
                                              this.userInfo.balance &&
                                              this.userInfo.balance[
                                                  token._id
                                              ] &&
                                              this.userInfo.balance[token._id]
                                                  .balance,
                                      ) || "0",
                                  image: `${token._id}.png`,
                                  type: token.type, // token || point || collect
                              };
                          })
                          .sort((a, b) => a.name.localeCompare(b.name))
                    : [];

            return {
                all: allTokens,
                solana: allTokens.filter(
                    (token) =>
                        token._id === "SOL" ||
                        token.chains?.find((chain) => chain.chainId === "SOL"),
                ),
                ton: allTokens.filter((token) => token.chainId === "TON"),
                point: allTokens.filter((token) => token.type === "point"),
                cantWithdraw: allTokens.some(
                    (token) =>
                        (allTokens.length && token.cantWithdraw) ||
                        (token && !token.withdraw) ||
                        // 沒有 transfer 方法 也沒有 tonwallet 方法
                        // 沒有 transfer 方法
                        (!(
                            token &&
                            token.withdraw &&
                            token.withdraw.methods &&
                            token.withdraw.methods["TRANSFER"]
                        ) &&
                            // 沒有 tonwallet 方法
                            !(
                                token &&
                                token.withdraw &&
                                token.withdraw.methods &&
                                token.withdraw.methods["TONWALLET"]
                            )),
                ),
                cantDeposit: allTokens.some(
                    (token) => allTokens.length && !token.deposit,
                ),
                cantTransfer: allTokens.some(
                    (token) => !this.transferConfig(token),
                ),
            };
        },
    },
    methods: {
        async updateBalance(isInitial = false) {
            if (isInitial) {
                this.isLoading = true;
            } else {
                this.isFetching = true;
            }
            try {
                const err = await this.$store.dispatch("updateUserInfo");
                if (err) {
                    throw new Error(JSON.stringify(err));
                } else {
                    this.balanceUpdatedTime = moment().format(
                        "YYYY-MM-DD HH:mm:ss",
                    );
                }
            } catch (err) {
                window.Telegram.WebApp.showAlert(err);
            }
            if (isInitial) {
                this.isLoading = false;
            } else {
                this.isFetching = false;
            }
        },
        connectWalletBtnOnClick() {
            const isConnected =
                this.currentConnectedWalletAddress &&
                this.currentConnectedWalletAddress.length;
            if (isConnected) {
                this.$bus.$emit("switchWallet", (err) => {
                    console.log(err);
                });
                // this.isWalletConnectPanelActive = true;
            } else {
                this.$bus.$emit("connectWallet", (err) => {
                    console.log(err);
                });
                // this.shouldConnect = !this.shouldConnect;
            }
        },
        thirdPartyTransferOnClick(token) {
            this.$router.push({
                name: "withdraw",
                query: {
                    method: "thirdPartyTransfer",
                    token: token._id,
                },
            });
        },
        selectToken(tokenName) {
            handleSelectToken(tokenName);
        },
        transferConfig(token) {
            return handleTransferConfig(token);
        },
    },
    created() {
        this.updateBalance(true);
    },
};
</script>

<style lang="scss">
.token-tabs {
    .ant-tabs-nav {
        .ant-tabs-tab {
            font-size: 16px;
            color: #9699b5;
            padding: 10px 0;
            margin-right: 16px;

            &:hover,
            &-active {
                font-weight: 700;
            }
        }

        .ant-tabs-ink-bar {
            border-radius: 2px;
        }
    }
}
</style>

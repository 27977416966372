<template>
    <div
        class="relative z-10 h-[126px] min-h-[126px] w-full overflow-hidden rounded-2xl"
    >
        <a-carousel
            autoplay
            :dots="false"
            class="h-full w-full"
            :autoplaySpeed="3000"
        >
            <div
                @click="() => promotionBannerOnClick(promotionBanner)"
                v-for="promotionBanner in promotionBanners"
                :key="promotionBanner._id"
                class="h-full w-full"
            >
                <img
                    :src="bannerImg(promotionBanner)"
                    alt=""
                    class="h-[126px] w-full object-cover"
                />
            </div>
        </a-carousel>
    </div>
</template>

<script>
import envelopeApi from "@/apis/envelope";

export default {
    name: "PromotionBanner",
    data: () => ({
        promotionBanners: null,
    }),
    methods: {
        async getPromotionBanner() {
            try {
                const res = await envelopeApi.getBanner();
                console.log("getPromotionBanner res: ", res);
                if (res.status !== 200) {
                    throw new Error();
                } else {
                    const banners = res.data;
                    this.promotionBanners = banners;
                }
            } catch (err) {
                console.log("getPromotionBanner err: ", err);
            }
        },
        bannerImg(bannerInfo) {
            let picSrc = bannerInfo && bannerInfo.pic;
            const isLocalhost = window.location.hostname === "localhost";
            if (isLocalhost) {
                picSrc = `https://devmini.tongifts.app${picSrc}`;
            }
            return picSrc;
        },
        targetLink(bannerInfo) {
            return bannerInfo && bannerInfo.link;
        },
        targetGiftId(bannerInfo) {
            return bannerInfo && bannerInfo.redEnvelope;
        },
        promotionBannerOnClick(bannerInfo) {
            // 如果有 指定禮物id 就跳轉開禮物
            if (this.targetGiftId(bannerInfo)) {
                this.$router.push({
                    name: "receive-gifts",
                    query: { id: this.targetGiftId(bannerInfo) },
                });
                return;
            }
            // 如果沒有 指定禮物id，有 link 就直接跳 link
            if (this.targetLink(bannerInfo)) {
                window.open(this.targetLink(bannerInfo), "_blank");
                return;
            }
        },
    },
    created() {
        this.getPromotionBanner();
    },
};
</script>

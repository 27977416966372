<template>
    <div class="flex w-full flex-col gap-4">
        <span class="text-lg font-bold text-[#111111]">{{
            $t("utils.popular")
        }}</span>
        <Carousel
            v-if="list.length"
            class="popular-carousel !w-auto"
            :carousel-settings="settings"
        >
            <div
                class="!flex max-w-fit flex-col items-center gap-1.5 overflow-hidden"
                v-for="(item, index) in list"
                :key="index"
            >
                <img
                    @click="$router.push(item.detailLink)"
                    @error="
                        (e) =>
                            (e.target.src =
                                '/assets/imgs/utils/token/default.png')
                    "
                    class="size-[68px] rounded-lg hover:cursor-pointer"
                    :src="item.imgSrc"
                />
                <span
                    class="max-w-[68px] truncate text-xs font-bold text-[#111111]"
                    >{{ item.title }}</span
                >
                <button
                    @click="$router.push(item.detailLink)"
                    class="h-8 basis-1/5 rounded-lg bg-[#ff5c8e] px-4 text-center text-sm font-bold leading-[1.875rem] text-white hover:text-white"
                >
                    {{ $t("utils.open") }}
                </button>
            </div>
        </Carousel>
    </div>
</template>

<script>
import Carousel from "@/components/Carousel.vue";

export default {
    name: "Popular",
    components: {
        Carousel,
    },
    props: {
        list: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        settings: {
            slidesToShow: 4.25,
            centerMode: false,
            slidesToScroll: 4,
        },
    }),
};
</script>

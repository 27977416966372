<template>
    <div
        @click="everyOnclick"
        id="app"
        class="top-0 flex h-screen max-h-screen w-screen flex-col justify-between bg-[#FFF]"
        :class="{ 'pb-5': isMobile }"
        :data-platform="platform"
    >
        <div
            class="relative flex flex-shrink flex-grow overflow-auto px-6 py-4"
        >
            <router-view :key="$route.path"></router-view>
        </div>
        <Navigator class="z-0 w-full" />
        <WalletConnector />
        <DailyCheckInNotifier
            :shouldCheckIn="shouldCheckIn"
            v-if="!['detail', 'discover', 'receive-gifts'].includes($route.name)"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import userAPI from "@/apis/user";
import walletAPI from "@/apis/wallet";
import taskAPI from "@/apis/task";
import telegramEvents from "@/utils/telegramEvents";
import WalletConnector from "@/components/walletConnector/WalletConnector.vue";
import DailyCheckInNotifier from "./components/DailyCheckInNotifier.vue";
import Navigator from "@/components/Navigator.vue";
import liff from "@line/liff";
import envelopeApi from "./apis/envelope";

export default {
    name: "App",
    components: {
        Navigator,
        WalletConnector,
        DailyCheckInNotifier,
    },
    data: () => ({
        isCurrentRouteRoot: true,
        isMobile: false,
        shouldCheckIn: false,
    }),
    computed: {
        ...mapGetters(["platform"]),
    },
    watch: {
        $route(to, from) {
            console.log("to: ", to);
            console.log("from: ", from);
            if (to.meta.isRoot) {
                this.isCurrentRouteRoot = true;
                window.Telegram.WebApp.BackButton.hide();
            } else {
                this.isCurrentRouteRoot = false;
                window.Telegram.WebApp.BackButton.show();
            }
            if (from.name === "detail" || from.name === "discover" || from.name === "receive-gifts") {
                this.shouldCheckIn = false;
            }
        },
    },
    methods: {
        // "telegram" || "line"
        async login(platform) {
            try {
                let err;
                if (platform === "line") {
                    const lineData = await liff.getProfile();
                    err = await this.$store.dispatch(
                        "updateInitLineUserInfo",
                        lineData,
                    );
                } else {
                    err = await this.$store.dispatch("updateInitDataUserInfo");
                }

                if (err) {
                    throw new Error(JSON.stringify(err));
                } else {
                    this.getTokenList();
                }
            } catch (err) {
                if (platform === "line") {
                    // show liff alert
                } else {
                    window.Telegram.WebApp.showAlert(err);
                }
            }
        },
        async getTokenList() {
            try {
                const res = await walletAPI.getTokenList();
                if (res.status !== 200) {
                    throw new Error(res.data);
                } else {
                    console.log("getTokenList res: ", res);
                    const tokenList = res.data;
                    // tokenList.sort((a, b) => {
                    //     if (a._id === 'TON') return -1;
                    //     if (b._id === 'TON') return 1;
                    //     if (a._id === 'USDT') return -1;
                    //     if (b._id === 'USDT') return 1;
                    //     return 0;
                    // });
                    tokenList.sort((a, b) => a.index - b.index);
                    this.$store.commit("setTokenList", tokenList);

                    // update current token
                    const localStorageCurrentTokenName =
                        localStorage.getItem("currentToken");
                    const targetToken =
                        tokenList &&
                        tokenList.find(
                            (token) =>
                                token._id === localStorageCurrentTokenName,
                        );
                    if (targetToken) {
                        this.$store.commit("setCurrentToken", targetToken);
                    } else {
                        // 沒有本地的 currentToken 就默認 TON
                        const TONToken =
                            tokenList &&
                            tokenList.find((token) => token._id === "TON");
                        this.$store.commit(
                            "setCurrentToken",
                            TONToken || tokenList[0],
                        );
                    }
                }
            } catch (err) {
                console.log("getTokenList err: ", err);
                window.Telegram.WebApp.showAlert(err);
            }
        },
        registerTelegramEvents() {
            for (const eventName in telegramEvents) {
                const listener = telegramEvents[eventName];
                for (const callbackId in listener) {
                    const callback = listener[callbackId];
                    window.Telegram.WebApp.onEvent(
                        eventName,
                        ({ button_id }) => {
                            if (button_id === callbackId) {
                                callback();
                            }
                        },
                    );
                }
            }
        },
        handleTelegramBackBtnClicked() {
            window.Telegram.WebApp.onEvent("backButtonClicked", () => {
                // 特例處理 (從資產頁返回上一頁固定回到首頁)
                if (this.$route.name === "assets") {
                    this.$router.replace("/wallet");
                    return;
                }
                // 有上一页则返回
                if (window.history.length > 1) {
                    // this.$router.go(-1);
                    window.history.back();
                } else {
                    // 没有上一页则返回到首页
                    this.$router.replace("/wallet");
                }
                // history.back();
                //this.$router.push({ name: "index" });
            });
        },
        tgInit() {
            if (window.Telegram && window.Telegram.WebApp) {
                const webApp = window.Telegram.WebApp;
                console.log(window.Telegram.WebApp);
                webApp.expand();
                webApp.enableClosingConfirmation();
                webApp.disableVerticalSwipes();
                console.log(webApp);
                if (webApp.initDataUnsafe.start_param) {
                    userAPI.event({
                        eventType: "start_app",
                        eventData: webApp.initDataUnsafe.start_param,
                    });
                    this.getMiniappParameter(webApp.initDataUnsafe.start_param);
                    this.$store.commit("setLaunchMethod", "MINIAPP"); // 调用 mutation
                }
                // set theme colors
                console.log("theme color: ", webApp.themeParams);
            } else {
                console.error("Telegram or Telegram.WebApp is not available.");
            }
        },
        checkIsMobile() {
            const isMobile =
                /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent,
                );
            this.isMobile = isMobile;
        },

        // get miniapp parameter
        getMiniappParameter(start_param) {
            console.log("start_param", start_param);

            // 使用 split 方法按 "_" 切分字符串
            const parts = start_param.split("_");
            // 第一个元素作为命令
            const command = parts[0];
            // 剩余的所有元素作为参数数组
            const params = parts.slice(1);

            // let command = "";
            // let parameter = "";
            // let index = start_param.indexOf('_');
            // if(index === -1){
            //     command = start_param;
            // }else{
            //     command = start_param.slice(0,index);
            //     parameter = start_param.slice(index+1);
            // }
            console.log("command", command);
            console.log("params", params);

            const commandMap = {
                // Check Gifts
                c: function (_this, _giftId) {
                    console.log("Check Gift");
                    console.log("_giftId: ", _giftId);
                    _this.$router.push(`/receiveGifts?id=${_giftId}&check=1`);
                },
                // fix URL Gifts
                g: function (_this, fixGiftId, _inviterId) {
                    if (_inviterId)
                        _this.$router.push(
                            `/receiveGifts?fgid=${fixGiftId}&inviterid=${_inviterId}`,
                        );
                    else _this.$router.push(`/receiveGifts?fgid=${fixGiftId}`);
                },
                // Open Gifts
                o: async function (
                    _this,
                    _giftId,
                    _inviterId, // or m params
                    _connectedAddressSuffix,
                ) {
                    console.log("_giftId: ", _giftId);
                    console.log("_inviterId: ", _inviterId);
                    console.log(
                        "_connectedAddressSuffix: ",
                        _connectedAddressSuffix,
                    );
                    // Post third party info when having m params
                    if (_inviterId === "m") {
                        _this.$router.push(
                            `/receiveGifts?id=${_giftId}&inviterid=${_connectedAddressSuffix}`,
                        );
                        await envelopeApi.postThirdPartyInfo(
                            _giftId,
                            _connectedAddressSuffix,
                        );
                        console.log("postThirdPartyInfo");
                    }
                    // 從 promotion 外部頁面跳轉到 mini app 時，如果有帶 address，就自動綁定
                    if (_connectedAddressSuffix) {
                        console.log(
                            "_connectedAddressSuffix: ",
                            _connectedAddressSuffix,
                        );
                        const connectedAddress = `0:${_connectedAddressSuffix}`;
                        taskAPI.tonConnect(connectedAddress).then((res) => {
                            if (res.data !== "Success") {
                                this.$message.error(
                                    this.$t(
                                        "messages.errors.walletHasBeenConnectedError",
                                    ),
                                    5,
                                );
                            }
                        });
                    }

                    if (_inviterId) {
                        _this.$router.push(
                            `/receiveGifts?id=${_giftId}&inviterid=${_inviterId}`,
                        );
                    } else _this.$router.push(`/receiveGifts?id=${_giftId}`);
                },
                t: function (_this, _thirdPartyOrderId) {
                    if (_thirdPartyOrderId) {
                        _this.$router.push({
                            name: "third-party-order",
                            query: {
                                id: _thirdPartyOrderId,
                            },
                        });
                    }
                },
                // 第三方轉帳 third-party transfer
                tt: function (_this, _tokenName, _amount) {
                    if (_tokenName) {
                        _this.$router.push({
                            name: "withdraw",
                            query: {
                                method: "thirdPartyTransfer",
                                token: _tokenName,
                                amount: _amount,
                            },
                        });
                    }
                },
                // Open detail page
                d: function (_this, _appId) {
                    if (_appId === "discover") {
                        _this.$router.push({ name: "discover" });
                        return;
                    }
                    _this.$router.push(`/detail?id=${_appId}`);
                },
            };

            if (command && params[0] && commandMap[command]) {
                commandMap[command](this, params[0], params[1], params[2]);
                return;
            }

            // 兼容过去已发出的红包
            this.$router.push(`/receiveGifts?id=${start_param}`);
        },
        //! 留著 用來觸發 blur，解決 input click outside unfocus 失效問題
        everyOnclick(event) {},
    },
    async created() {
        await this.$store.dispatch("updateDiscover");
        console.log("06071038");
        console.log("current env", process.env.NODE_ENV);
        console.log("current VUE_APP_BOT_URL", process.env.VUE_APP_BOT_URL);
        this.checkIsMobile();
        if (liff.isInClient()) {
            if (!liff.isLoggedIn()) {
                liff.login();
                this.login("line");
            }
        } else {
            this.tgInit();
            this.login("telegram");
            this.registerTelegramEvents();
            this.handleTelegramBackBtnClicked();
            // this.$store.commit("setPlatform", "line");
        }
        await this.$store.dispatch("updateOpenAd");
    },

    mounted() {
        window.Telegram.WebApp.ready();
    },
};
</script>

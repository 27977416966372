export default {
    tokenTabs: [
        {
            key: "all",
            name: "All",
        },
        {
            key: "solana",
            name: "Solana",
        },
        {
            key: "ton",
            name: "Ton",
        },
        {
            key: "point",
            name: "Points",
        },
        {
            key: "collect",
            name: "Collect",
        },
    ],
};
